import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'

import { injected } from '../components/connectors'

export function useAutoConnectInjected() {
  //get the necessary web3 context 
  const { active, activate } = useWeb3React();

  const [tried, setTried] = useState(false);
  //*debug*console.log("using useAutoConnectInjected...");
  //*debug*console.log("tried: ", tried);

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        //*debug*console.log("auto-activating injected connector");
        activate(injected, undefined, true).catch((err) => {
          console.log("caught error auto-activating injected connector:", err);
          setTried(true);
        });
      } else {
        //*debug*console.log("injected is not authorized, setting tried to true");
        setTried(true);
      };
    });
  }, [activate]);

  // useEffect(() => {
  //   async function tryConnect() {
  //     try {
  //       const auth = await injected.isAuthorized();
  //       if (auth) {
  //         try {
  //           console.log("auto-activating injected connector")
  //           activate(injected, undefined, true)
  //         } catch (err) {
  //           console.log("caught error when auto-activating injected connector: " + err);
  //           setTried(true);
  //         };
  //       } else {
  //         setTried(true);
  //       };
  //     } catch (error) {
  //       console.log("error testing injected connector for authorization, in auto-connect block: " + error);
  //       setTried(true);
  //     };
  //   };
  //   tryConnect();
  // }, []); //run once on first render

  //confirm the auto connect worked before setting tried to true
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    };
  }, [tried, active]);

  return tried
}

export function useInjectedMonitor(suppress=false) {
  //get the necessary web3 context
  const { active, error, activate } = useWeb3React();
  
  useEffect(() => {
    //get the injected object from i.e. MetaMask
    const { ethereum } = window;
    //deal with events on the injected provider
    if (ethereum && ethereum.on && !active && !suppress) {
      const handleConnect = () => {
        console.log("Connecting the injected provider (i.e. Metamask)");
        activate(injected);
      };
      const handleChainChanged = (chainId) => {
        console.log("Chain changed, connecting to chain #" + chainId);
        activate(injected);
      };
      const handleAccountsChanged = (accounts) => {
        console.log("handling accountsChanged, with accounts: " + accounts);
        if (accounts.length > 0){
          activate(injected);
        };
      };
  
      ethereum.on("connect", handleConnect);
      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);
  
      //cleanup listeners on return
      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("connect", handleConnect);
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
        };
      };
    };
  }, [active, error, suppress, activate]);
}