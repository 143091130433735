import erc20Abi from "./abis/erc20.json";
import ownableAbi from "./abis/ownable.json";
import derpysAbi from "./abis/Derpys.json";
import curveKillaAbi from "./abis/DerpysCurveKilla.json";
import derpySpooksAbi from "./abis/DerpySpooks.json";

const abis = {
  erc20: erc20Abi,
  ownable: ownableAbi,
  derpys: derpysAbi.abi,
  curveKilla: curveKillaAbi.abi,
  derpySpooks: derpySpooksAbi.abi,
};

export default abis;
