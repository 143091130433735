

export const Config = {
	ipfsGatewayUrl: "https://ipfs.io/ipfs/",
	//ENDED PINATA GATEWAY SUBSCRIPTION ipfsDedicatedGatewayUrl: "https://missionderpy.mypinata.cloud/ipfs/",
	ipfsTokenCid: "QmdwPsqEP9pr7Xj2mkHd17xMs67EMWuHfEeiFc9Y9Ny1Tr",//"QmdNndvdhT3PARAHts9Juug6YLh2SsSDjoRmQNmrfiDyfJ",//"QmdBSMEGehGZnbewwirYxKqGGkFzmgKb4uvbcX8Lyfet9o",
	ipfsTokenCid_Mainnet: "QmdwPsqEP9pr7Xj2mkHd17xMs67EMWuHfEeiFc9Y9Ny1Tr",//QmdBSMEGehGZnbewwirYxKqGGkFzmgKb4uvbcX8Lyfet9o",

	ipfsTokenStatsCid: "QmSjjc96UzvYeds8isjkcwUmY1UKtnYja9orYaXAdrmdDJ",//"Qmf1GXQWgMKmogjrFkrEPq5kGH8prEG8Tqrtjnq9mVQRxA",//"QmYmuRxJYZtyqZpwyz47VbvA2Vr3kKQrsTtSUctEp7SMt7",
	ipfsTokenStatsCid_Mainnet: "QmSjjc96UzvYeds8isjkcwUmY1UKtnYja9orYaXAdrmdDJ",//"QmNpMV9yyBphW7LdZMh3VoEmXPUygCMdGJ73deFn9JYXdd",
	ipfsTokenStatsCid_Mainnet_UPDATE_TO_THIS: "QmXnd5ophFPuwLKd8ug4hQDLraas3fJJqyKrp9W5wcHbJu",//FIXED METADATA WITH NEW WEBURL

	ipfsSpooksTokenCid: "QmacTr5nkcAmgrWsy4GPfQwhR8kzjVLHTe1xpZNmbY4D97",
	ipfsSpooksTokenCid_Mainnet: "QmacTr5nkcAmgrWsy4GPfQwhR8kzjVLHTe1xpZNmbY4D97",

	ipfsSpooksTokenStatsCid: "QmeoULfehgcFrpFiHFqczYjKGx6jghD6eT6Pquqakqa9j2",
	ipfsSpooksTokenStatsCid_Mainnet: "QmeoULfehgcFrpFiHFqczYjKGx6jghD6eT6Pquqakqa9j2",

	ipfsChubbiesTokenCid: "QmNdNTQVE4BUpERFwVTxX2RwZNbTCSwQr5pZ83EJJsBfWW",
	ipfsChubbiesStatsCid: "QmXDvsnPyo9iPQHv7KtoDzjp57FMTi7cvkFFuWUApY8kro",
	metaMaskLink: "https://metamask.io/download",
	openseaLink: "https://opensea.io/collection/derpys",
	openseaAssetRoot: "https://opensea.io/assets/",
	twitterLink: "https://twitter.com/DerpysNFT",
	instagramLink: "https://instagram.com/derpysnft",
	discordLink: "https://discord.gg/WbnUPfRgEQ",
	whitepaperLink: "https://derpys.io/DerpysWhitepaper.pdf",
	etherscanBaseUrl: "https://etherscan.io/address/",
	twitterLink_CoinFuPanda: "https://twitter.com/CoinFuPanda",
	twitterLink_NFTweety: "https://twitter.com/NFTweety",
}
