
// This address points to a dummy ERC20 contract deployed on Ethereum Mainnet,
// Goerli, Kovan, Rinkeby and Ropsten. Replace it with your smart contracts.
const addresses = {
  derpysLocal: "0x5FbDB2315678afecb367f032d93F642f64180aa3",
  derpysRopsten: "0xeD5D565659bB7ca7c09c06742881221A3ceD034d",
  derpysRinkeby_new: "0xC79a9Eb5d29418d4f29C405F637f832C24F8B64A",
  derpysRinkeby: "0x58Da5E780324d0A173347Fcbc3fA11f20C900fC5", //switch back to this when ck is tested. Try applying ck to this one.

  curveKillaRinkeby: "0xF767557048896c85D00468dDE3A33f53246DF9D8",//"0x7ae44D700D9f3b99593116fE5B9b2444f4A54991", //"0xe3cEA68DC473ee9C8637507e4171a7cdc458cDFF", //"0xaB08871E0379A677181e9C356529878074378e33", //"0x383Fd44B2Efa788a80F25d6eE46038f696E676C1",

  derpySpooksRinkeby: "0xA4E78bb762220C678231d6187C703A573111C33f",//"0x91e4368F89B37113e85888df7EBB28a73480019d",

  derpysMainnet: "0xbc1e44df6a4c09f87d4f2500c686c61429eea112",
  curveKillaMainnet: "0x243dfd80c89C7Bd869e1A62d2EB924085e65Fb1D",
  derpySpooksMainnet: "0x80b0d86F03c0082931F2ac9078534a90183b94d4",
};

export default addresses;
