import { Config } from "../config/default";

// import addresses from "../contracts/src/addresses.js";

// import gladiatorDiamonds from "../media/gladiatorDiamonds.gif";
// import laserTurtle from "../media/laserTurtle.gif";
import fiveDerps from "../media/fiveDerps.gif";
import admin from "../media/admin.gif";
import guest from "../media/guest.gif";
import genesisDerp from "../media/Genesis.gif";
import contactDerp from "../media/Contact.gif";
import contactDerp2 from "../media/Contact2.gif";
import derpyDetailsDerp from "../media/DerpyDetails.gif";
import howToCatchDerp from "../media/HowToCatch.gif";
import lootDerp from "../media/Loot.gif";
import whyDerp from "../media/Why.gif";
import pricing1 from "../media/pricing1.gif";
import pricing2 from "../media/pricing2.gif";


import dGif1 from "../media/sampleDerpys/1.gif";
import dGif2 from "../media/sampleDerpys/2.gif";
import dGif3 from "../media/sampleDerpys/3.gif";
import dGif4 from "../media/sampleDerpys/4.gif";
import dGif5 from "../media/sampleDerpys/5.gif";
import dGif6 from "../media/sampleDerpys/6.gif";
import dGif7 from "../media/sampleDerpys/7.gif";
import dGif8 from "../media/sampleDerpys/8.gif";
import dGif9 from "../media/sampleDerpys/9.gif";
import dGif10 from "../media/sampleDerpys/10.gif";
import dGif11 from "../media/sampleDerpys/11.gif";
import dGif12 from "../media/sampleDerpys/12.gif";
import dGif13 from "../media/sampleDerpys/13.gif";
import dGif14 from "../media/sampleDerpys/14.gif";
import dGif15 from "../media/sampleDerpys/15.gif";
import dGif16 from "../media/sampleDerpys/16.gif";
import dGif17 from "../media/sampleDerpys/17.gif";
import dGif18 from "../media/sampleDerpys/18.gif";
import dGif19 from "../media/sampleDerpys/19.gif";
import dGif20 from "../media/sampleDerpys/20.gif";
import dGif21 from "../media/sampleDerpys/21.gif";
import dGif22 from "../media/sampleDerpys/22.gif";
import dGif23 from "../media/sampleDerpys/23.gif";
import dGif24 from "../media/sampleDerpys/24.gif";
import dGif25 from "../media/sampleDerpys/25.gif";
import dGif26 from "../media/sampleDerpys/26.gif";
import dGif27 from "../media/sampleDerpys/27.gif";
import dGif28 from "../media/sampleDerpys/28.gif";
import dGif29 from "../media/sampleDerpys/29.gif";
import dGif30 from "../media/sampleDerpys/30.gif";
import dGif31 from "../media/sampleDerpys/31.gif";
import dGif32 from "../media/sampleDerpys/32.gif";

// import dsGif1 from "../media/sampleSpooks/1.gif";
// import dsGif2 from "../media/sampleSpooks/2.gif";
// import dsGif3 from "../media/sampleSpooks/3.gif";
// import dsGif4 from "../media/sampleSpooks/4.gif";
// import dsGif5 from "../media/sampleSpooks/5.gif";
// import dsGif6 from "../media/sampleSpooks/6.gif";
// import dsGif7 from "../media/sampleSpooks/7.gif";
// import dsGif8 from "../media/sampleSpooks/8.gif";
// import dsGif9 from "../media/sampleSpooks/9.gif";
// import dsGif10 from "../media/sampleSpooks/10.gif";
// import dsGif11 from "../media/sampleSpooks/11.gif";
// import dsGif12 from "../media/sampleSpooks/12.gif";
// import dsGif13 from "../media/sampleSpooks/13.gif";
// import dsGif14 from "../media/sampleSpooks/14.gif";
// import dsGif15 from "../media/sampleSpooks/15.gif";
// import dsGif16 from "../media/sampleSpooks/16.gif";
// import dsGif17 from "../media/sampleSpooks/17.gif";
// import dsGif18 from "../media/sampleSpooks/18.gif";
// import dsGif19 from "../media/sampleSpooks/19.gif";
// import dsGif20 from "../media/sampleSpooks/20.gif";
// import dsGif21 from "../media/sampleSpooks/21.gif";
// import dsGif22 from "../media/sampleSpooks/22.gif";
// import dsGif23 from "../media/sampleSpooks/23.gif";
// import dsGif24 from "../media/sampleSpooks/24.gif";
// import dsGif25 from "../media/sampleSpooks/25.gif";
// import dsGif26 from "../media/sampleSpooks/26.gif";
// import dsGif27 from "../media/sampleSpooks/27.gif";
// import dsGif28 from "../media/sampleSpooks/28.gif";
// import dsGif29 from "../media/sampleSpooks/29.gif";
// import dsGif30 from "../media/sampleSpooks/30.gif";
// import dsGif31 from "../media/sampleSpooks/31.gif";
// import dsGif32 from "../media/sampleSpooks/32.gif";


export const SOCIALTWITTER = {
  title: "twitter",
  message: "Derpys Twitter",
  href: Config.twitterLink
};

export const SOCIALINSTAGRAM = {
  title: "instagram",
  message: "Derpy Selfies",
  href: Config.instagramLink
};

export const SOCIALDISCORD = {
  title: "discord",
  message: "Discord Community",
  href: Config.discordLink
};

export const SOCIALOTHER = {
  title: "opensea",
  message: "Opensea trading!",
  href: Config.openseaLink
};

export const TITLE = {
  title: "DERPYS",
  messageBold: "SITREP: 10,000 Derpys have escaped from the AETHER",
  message: 
    <div>
      <p>
        PROTOCOL: Catch-All
      </p>
    </div>
};

export const SITUATIONREPORT = {
  title: "SITUATION REPORT:",
  message:
    <div className="justify-text" >
      <h3 style={{color: "var(--color-accent-pink)"}}>
        <strong>{">>"} A CATASTROPHIC RIFT HAS BEEN IDENTIFIED IN THE AETHER!</strong>
      </h3>

      <p>
        <strong>TORN APART BY AN ALPHA SURGE IN THE COSMOS,</strong> the fabric of the universe lies open, exposing a primordial world. 
        <strong> THOUSANDS OF DERPYS ARE TUMBLING OUT OF THE VOID.</strong> Dazed and confused, they are out of place and a long way from home! 
        Derpys are scampering around, collecting accessories, trying to fit in. But with no home to go back to, they are beginning to go AWOL...
      </p>
      <h3 style={{color: "var(--color-accent-pink)"}}>
        <strong>CHAOS LOOMS...</strong>
      </h3>
      <p>
        <strong>DERPY HQ</strong> has identified a cluster of 10,000 mischievous Derpys, and initiated a <strong>'CATCH-ALL'</strong> protocol. 
        We need <strong>YOUR</strong> help to catch these Derpys, before Earth is overrun!
        Let's get Derpys safely on-chain. <strong>GOOD LUCK OUT THERE.</strong>
      </p>
    </div>
}


export const DERPYDNACOLS = [
  {
    key: "col-stats",
    src: genesisDerp,
    alt: "derpy stats",
    title: "GENESIS",
    message: 
      <div>
        <p style={{margin: "0", letterSpacing: "0.02em"}}>The <strong>GENESIS DERPY</strong>, naturally gifted with shapeshifting abilities.</p>
        <p style={{margin: "0", letterSpacing: "0.02em"}}>Inhabitant of planet <strong>DELYSIUM</strong>, core layer in the <strong>NEREUS-III</strong> world-chain.</p>
      </div>
  },
  {
    key: "col-traits",
    src: fiveDerps,
    alt: "derpy traits",
    title: "SPLIT",
    message: <p style={{margin: "0", letterSpacing: "0.02em"}}>Falling through the Aether-rift altered the Derpys' genetic code... Most locked into one colour and lost the ability to shapeshift.</p>
  },
  {
    key: "col-accessories",
    src: lootDerp,
    alt: "derpy accessories",
    title: "LOOT",
    message: <p style={{margin: "0", letterSpacing: "0.02em"}}>Making up for lost powers, Derpys collect items, some glitch into different forms... They attempt to blend in...</p>
  }
]


export const DERPYDNACONTENT = {
  message:
    <div className="justify-text">
      <p>
      The Derpys got a little frazzled when they fell through the rift, and their personalities became UNSTABLE to say the least. They all now have <strong>RANDOMLY GENERATED TRIPLE-PERSONAS... </strong>
      </p>
      <p style={{textAlign: "center", color: "var(--color-accent-pink)", margin: "2em 0"}}>
        <strong>ALL DERPYS ARE UNIQUE - THERE ARE NO TWO IDENTICAL DERPYS!</strong>
      </p>
      <p>
        Easily influenced by popular culture, 
        some Derpys shifted into familiar forms in an attempt to fit in... 
        <strong><span style={{color: "red"}}> APES</span></strong>, 
        <strong><span style={{color: "green"}}> ZOMBIES</span></strong> and 
        <strong><span style={{color: "deeppink"}}> PUNKS</span></strong> have been spotted, as well as 
        <strong><span style={{color: "cyan"}}> ASTRONAUTS</span></strong>, 
        <strong><span style={{color: "coral"}}> MUMMYS</span></strong> and 
        <strong><span style={{color: "blueviolet"}}> DRAGONS! </span></strong>
      
      It doesn't stop there - they've collected items like <strong><span style={{color: "darkcyan"}}>SPIRIT BLADES</span>, <span style={{color: "red"}}>FLAMEBALLS</span>,</strong> and <strong><span style={{color: "black"}}>DUCKS</span>. </strong>
      Not to metion the <strong><span style={{color: "cyan"}}>DIAMOND HANDS</span></strong> and <strong><span style={{color: "magenta"}}>LASER EYES</span></strong>!</p>
      <br/>
      <p className="center-text"><strong>There are 33,111,823,852,800 possible combinations of attributes</strong></p>
      <p style={{textAlign: "center", color: "var(--color-accent-pink)"}}><strong>THAT'S OVER THIRTY THREE TRILLION POSSIBLE COMBOS</strong></p>         
    </div>
}


export const HOWTOCATCHADERPY = {
  title: "HOW TO CATCH A DERPY",
  message: 
    <ol>
      <li>Download and install <a href={Config.metaMaskLink} className="metamask-link" target="_blank" rel="noopener noreferrer">MetaMask</a>, a browser plugin. This will allow websites (that you authorize) to access your Ethereum account</li>
      <li>(If you have a Ledger or Trezor, you can connect to it through MetaMask - choose "connect hardware wallet" in MM)</li>
      <li>If you would like to mint a Derpy, you'll need some Ether</li>
      <li>When MetaMask is installed, you can connect to this site with any of the CONNECT WALLET buttons!</li>
      <li>Then head to the <a href={"#mintBarId_0"}>Minting Section</a>, choose how many Derpys to catch, check the price, and press MINT!</li>
    </ol>
  ,
  media: {
    preContent: {src: howToCatchDerp, alt: "Space Derpy inna beanie!"}
  }
}

// export const DERPYMAINTENANCE = {
//   title: "WE'VE BEEN OVERRUN!",
//   message: [
//     "TEMPORARY OUTAGE! DO NOT WORRY YOUR DERPYS ARE SAFU.",
//     "We're working as fast as we can to fix some tricksy bits in our website - the contract and ipfs are not harmed. Repeat, not harmed.",
//     "We'll be back online as soon as possible - it's not fair to sell you Derpys when we're not providing the best experience possible, so we'll be back up when it's all ironed out :)",
//     <p><a href={"#contactId"}>Join our socials </a> to hear the latest updates, or <a href={Config.openseaLink} target="_blank" rel="noopener noreferrer">head to Opensea</a> to see the Derpys that have already been caught!</p>
//   ],
//   media: {
//     preContent: {src: gladiatorDiamonds, alt: "Gladiator Derpy with Diamond Hands"},
//     postContent: {src: gladiatorDiamonds, alt: "Gladiator Derpy with Diamond Hands"}
//   }
// }

export const DERPYDETAILS = {
  title: "DERPY DETAILS",
  message:
  <div className="justify-text">
    <p>
      Each Derpy is an ERC721 token, on the Ethereum blockchain. 
      They are non-fungible, so every token has its own ID, associated metadatata, 
      and is unique.
    </p>
    <p>
      You will be able to see the total number of DERP tokens you own 
      (all individual of course) in etherscan, or by adding the contract address 
      as a custom token in MetaMask. 
      DM us if you have any questions about this and we'll be happy to help you out!
    </p>
    <p>
      The actual gifs of the Derpys are too large to store on the blockchain, 
      so they are hosted on the IPFS - the InterPlanetary File System. 
      This is a decentralised way of storing, sharing and accessing data.
    </p>
    <p>
      Derpys are randomly generated from over 300 hand drawn attributes (our generation folder contains 2190 individually drawn layers). 
      They are generated as 32x32 pixel gifs and upscaled to 320x320 pixels for lookin' at.  
      The Derpy gifs have five frames, and run at 150 milliseconds per frame for an optimal derpy-bounce
    </p>

  </div>
  ,
  media: {
    postContent: {src: derpyDetailsDerp, alt: "Gladiator Derpy with Diamond Hands"}
  }
}

export const TEAM = {
  title: "DERPY TEAM",
  message: <div>
    <p className="center-text">
      <span style={{fontWeight: "bold"}}>We are a two person team, tweeting as: </span><a 
        className="pink-link bold-text" 
        href={Config.twitterLink} 
        target="_blank"
        rel="noopener noreferrer"
      >
        @DerpysNFT
      </a>
    </p>
    <p>
      Yes our twitter account is small - show us some love and give us a follow!<br/>
      Please DM with any questions / collabs etc. We'll get back to you asap!
    </p>
  </div>,
  media: {
    preContent: {src: admin, alt: ">>admin"},
    postContent: {src: guest, alt: ">>guest"}
  }
}

export const CONTACT = {
  title: "CONTACT",
  // message: [
  //   "Like - Follow - Collect - Share",
  //   "Let's find Derpys a new home!"
  // ],
  media: {
    preContent: {src: contactDerp, alt: "Derpy!"},
    postContent: {src: contactDerp2, alt: "Derpy!"}
  }
}

export const PRICING = {
  title: "PRICING",
  message: 
    <div className="center-text">{/*className="center-text">*/}
      <h2 style={{margin: "0 auto"}}>
        Derpys mint at<br/>0.045 ETH!<br/>
        <br/>
        <a className="turq-link bold-text" href="#mintBarId_0">
          Minting is LIVE!
        </a>
      </h2>
      
      <p>Welcome to the Derpy fam.<br/>LFG &#129308;&#129307;</p>

    </div>
    ,
  media: {
    preContent: {src: pricing2, alt: "Melon Thor"},
    postContent: { src: pricing1, alt: "Princess Derpy"}
  }
}

// export const SPOOKS101 = {
//   title: "What are the DerpySPOOKS?",
//   message: 
//     <div className="justify-text">
//       <p>
//         DerpySpooks are an official Derpys minicollection! They mint FREE to Derpy holders and
//         cost 0.02 ETH otherwise.
//       </p>
//       <p>
//         If you own a DerpySpook, you will be eligible for SpooksPrizes! These are lotteries 
//         drawn from secondary sales royalties. 50% of DerpySpooks royalties go into this prize fund!
//       </p>
//       <p>
//         (DerpySpooks are not eligible for Derpys prize draws including: The 25s, The lotteries or Bounty Hunts.
//           We will be running separate DerpySpooks draws funded by secondary OpenSea sales!
//       </p>
//       <h3 className="center-text" style={{margin: "0.5em auto"}}>
//         <a href={"#mintBarId_1"} className="turq-link">
//           Mint SPOOKS HERE
//         </a>
//       </h3>
//       <p className="center-text" style={{margin: "0 auto"}}>
//         Read the contract here:<br/> 
//         <a
//           href={Config.etherscanBaseUrl + addresses.derpySpooksMainnet + "#code"} 
//           target="_blank" 
//           rel="noopener noreferrer"
//         >
//           DerpySpooks
//         </a>
//       </p>
//     </div>,
//   media: {
//     postContent: {src: laserTurtle, alt: "laser eyes zombie turtle"}
//   }
// }

export const WHYDERPY = {
  title: "WHY DERPY?",
  message: 
    <div className="justify-text">
      <p><strong>Catch Derpys only because you love them! </strong>They're no Picassos, but they are a cute, collectable NFT collection!</p>
      <p>
        Derpys are <span className="bold-text pink-text">AWESOME: </span> Plus they're some of the most intricate pixel based NFTs out there.
        Derpys are <span className="bold-text pink-text">DECENTRALISED: </span> The contract is on Ethereum, and all the art is hosted on the IPFS.
        Derpys are <span className="bold-text pink-text">FAIR: </span> They are randomly generated, and you have equal chance of getting super rare ones at any point!
        </p>
      <p>
        Derpys have an insane amount of work which has gone into them. 
        They are the product many months' work, thousands of lines of code 
        and a huge amount of love.
        <span className="bold-text pink-text"> THERE ARE NO RUBBISH DERPYS: </span>
        Some are rarer than others, but 
        <span className="bold-text pink-text"> ALL ARE EPIC</span>
        , bright and mischevious
      </p>
      <br/>
      <p className="center-text"><strong>How can I trade my Derpys?</strong></p>
      <p>Any site like Opensea will let you trade them - they're ERC-721 compliant tokens!</p>
      <p className="center-text"><strong>Who owns my Derpys?</strong></p>
      <p>You own the NFT. Each Derpy NFT is an ERC721 token on the Ethereum blockchain. When you purchase an NFT, you own the underlying token, and that ownership is mediated by the Smart Contract and the Ethereum network.</p>
      <p className="center-text"><strong>A note on NFTs</strong></p>
      <p>If you have any questions about NFTs, or whether they're right for you, please seek professional advice. For general questions, please feel free to reach out to us and we'll do our best to answer them.</p>
    </div>,
  media: {
    postContent: { src: whyDerp, alt: "a LASER ZOMBIE!" }
  }
}

export function DERPYBLOCKGALLERY(numDerpys=32, startNum=0) {
  let gifList = [dGif1, dGif2, dGif3, dGif4, dGif5, dGif6, dGif7, dGif8, dGif9, dGif10, dGif11, dGif12, dGif13, dGif14, dGif15, dGif16, dGif17, dGif18, dGif19, dGif20, dGif21, dGif22, dGif23, dGif24, dGif25, dGif26, dGif27, dGif28, dGif29, dGif30, dGif31, dGif32];
  let jsonList = [];
  for ( let i = startNum; i < (startNum + numDerpys); i++ ) {
    jsonList.push(
      {
        key: `sd${i}`,
        src: gifList[i],
        alt: `Derpy #${i}!`
      }
    );
  };
  return jsonList
}

// export function DERPYSPOOKSBLOCKGALLERY(numDerpys=32, startNum=0) {
//   let gifList = [dsGif1, dsGif2, dsGif3, dsGif4, dsGif5, dsGif6, dsGif7, dsGif8, dsGif9, dsGif10, dsGif11, dsGif12, dsGif13, dsGif14, dsGif15, dsGif16, dsGif17, dsGif18, dsGif19, dsGif20, dsGif21, dsGif22, dsGif23, dsGif24, dsGif25, dsGif26, dsGif27, dsGif28, dsGif29, dsGif30, dsGif31, dsGif32];
//   let jsonList = [];
//   for ( let i = startNum; i < (startNum + numDerpys); i++ ) {
//     jsonList.push(
//       {
//         key: `sd${i}`,
//         src: gifList[i],
//         alt: `Derpy #${i}!`
//       }
//     );
//   };
//   return jsonList
// }