import _ from "./enableDotEnv"; //this imports and config()s dotenv

import { Config } from "./config/default";
import React from "react";
import { useMediaQuery } from "react-responsive";

//web3 stuff
import { Web3ReactProvider, useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
//import connectors from web3React i.e. injected, ledger, trezor...
import { injected, walletConnect } from "./components/connectors";
//import custom errors
import { NoEthereumProviderError, 
         UserRejectedRequestError as UserRejectedRequestErrorInjected 
        } from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";
import { useAutoConnectInjected } from "./hooks/useWeb3React";
// import { useInjectedMonitor } from "./hooks/useWeb3React";
import { ethers } from "ethers";

//contract interaction stuff 
import addresses from "./contracts/src/addresses.js";
import abis from "./contracts/src/abis.js";

//components
import { Body } from "./components"; //, Header, Button, Header, Image, Link }
import { DerpyScrollingGallery } from "./components/DerpyScrollingGallery.js";
import { 
  PostBlock,
  MediaBlock,
  //MediaBlockFlex,
  TitleBlock,
  //ExternalLinkButton,
  ExternalLinkAsButton,
  LinkButton,
  ColumnStatsBlock,
  MediaGallery,
  //SocialsBar,
  Post,
  AllWrapper,
  BgWrapper,
  ContentWrapper,
  HidingImage,
  // InfoPopup,
  //FlexColsBlock,
  //InPostMedia,
  // SocialTile,
  InfoByte,
  //SocialsFlex,
} from "./components/StaticComponents.js";
import { MintBar } from "./components/MintingComponents.js";
// import { MintBarDouble } from "./components/MintingComponents.js";
// import { 
//   RoadmapItem,
//   RoadmapRow,
//   Roadmap,
//   rmHeadItems,
//   rmR1Items,
//   rmRows,
//   rmExtraItems,
// } from "./components/RoadmapElements.js";
import { viaIpfsGateway } from "./components/IpfsHelpers.js";

//styling
import './App.css';

//media
//import logo from "./logo.svg";
import logo_metamask from "./media/logo_metamask.svg";
import logo_walletconnect from "./media/logo_walletconnect.svg";
import { Spinner } from "./components/Spinner.js";

//import bgVid3 from "./media/bg3.mp4";
import bgVid4 from "./media/bg4.mp4";
import bgPoster from "./media/bgPoster.jpg";

//import basicDerpy from "./media/basicderp.jpeg";
import derpyHead from "./media/derpyHead.png";
import derpyHeadGif from "./media/derpyHeadGif.gif";
//import admin from "./media/admin.gif";
//import guest from "./media/guest.gif";
//import bannerGif from "./media/bannerGif.gif";

// import genesisDerpy from "./media/Genesis.gif";
import sampleDerpy1 from "./media/sampleDerpy1.gif";

import { Power, X as XClose } from "react-feather";

//content
import { 
  //DERPYMAINTENANCE,
  // SOCIALTWITTER,
  // SOCIALINSTAGRAM,
  // SOCIALDISCORD,
  // SOCIALOTHER,
  //PRELAUNCHTITLE,
  //TEAM,
  TITLE,
  //TESTCONTENT,
  //TESTCONTENT2,
  //TESTCOLS,
  //TESTMEDIA,
  //TESTMEDIADERPY,
  DERPYBLOCKGALLERY,
  // DERPYSPOOKSBLOCKGALLERY,
  //PRELAUNCHDERPYDNACONTENT,
  DERPYDNACONTENT,
  //PRELAUNCHSITUATIONREPORT,
  SITUATIONREPORT,
  //PRELAUNCHHOWTOCATCHADERPY,
  HOWTOCATCHADERPY,
  DERPYDETAILS,
  //TEAM,
  WHYDERPY,
  DERPYDNACOLS,
  CONTACT,
  // ROADMAP,
  // CURVEKILLA101,
  // SPOOKS101,
} from "./content/testData.js";

const OPENSEAURL = Config.openseaLink;
const OPENSEA_ASSET_ROOT = Config.openseaAssetRoot;

//organise connector names into strings for referencing later
const connectorNames = {
  Injected: "MetaMask",
  WalletConnect: "walletConnect"
}
//organise the connectors to access them w.r.t. their string names
const connectorsByName = {
  [connectorNames.Injected]: injected,
  [connectorNames.WalletConnect]: walletConnect
}
//organise corresponding logos for each connector
const connectorLogosByName = {
  [connectorNames.Injected]: logo_metamask,
  [connectorNames.WalletConnect]: logo_walletconnect
}

// //set the contract address depending on network
// let derpyAddress = "";
// if (process.env.NODE_ENV === "development") {
//   derpyAddress = addresses.derpysRopsten;
//   console.log("using DEVELOPMENT contract address: ", derpyAddress);
// } else if (process.env.NODE_ENV === "test") {
//   derpyAddress = addresses.derpysTest;
//   console.log("using TEST contract address: ", derpyAddress);
// } else if (process.env.NODE_ENV === "production") {
//   derpyAddress = addresses.derpysProduction;
//   console.log("using PRODUCTION contract address: ", derpyAddress);
// };

//make react-feather icon into a component
let powerIcon = <Power className="power-icon"/>;

//get the convenience library (ethers) for the Web3ReactProvider
function getLibrary(provider) {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 12000;
  return library
}

//deal with errors
function getErrorMessage(err) {
  //*debug*console.log("getErrorMessage readout:");
  //*debug*console.log("GEM err: ", err);

  if (err instanceof NoEthereumProviderError) {
    return (<p>No Ethereum browser extension detected, <a href={Config.metaMaskLink} className="metamask-link" target="_blank" rel="noopener noreferrer">get MetaMask here</a> or visit from a dApp browser on mobile.</p>)
  } else if (err instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network! Derpys are loose on the Ethereum Mainnet, please connect there!"
  } else if (err instanceof UserRejectedRequestErrorInjected) {
    return "Please authorize this website to access your Ethereum account."
  } else if (err instanceof UserRejectedRequestErrorWalletConnect) {
    return "Please authorize this website to access your Ethereum account."
  }
  // else if (!!err && err.code.toString() === "-32002") {
  //   return "Please check you've connected and unlocked MetaMask! We're trying to access your accounts..."
  // } 
  else {
    console.error(err)
    return "Please check you have connected and unlocked MetaMask! Check the console (F12) for details"
  }
}

function HeaderBar(props) {
  return (
    <div className="header-bar" syle={props.style}>
      {props.children}
    </div>
    );
}

function ConnectOptionsModal({
  connector, 
  triedAutoConnect,
  activatingConnector,
  setActivatingConnector,
  activate,
  error,
  onOutsideClick,
  onConnect
  }) {
  //this should pop up as a modal when the 'connect wallet' button is clicked
  //attach a ref to it, to track the next click locations
  const connectRef = React.createRef();
  
  //close the modal by clicking anywhere outside it
  React.useEffect(() => {
    const checkClickLocation = (event) => {
      if (connectRef.current && !connectRef.current.contains(event.target)) {
        //clicked outside -> close the popup
        //*debug*console.log("onOutsideClick: ", onOutsideClick);
        onOutsideClick();
      };
    };
    document.addEventListener("click", checkClickLocation);
    return () => {
      document.removeEventListener("click", checkClickLocation)
    };
  }, [connectRef, onOutsideClick]);

  return (
    <div className="connect-modal-underlay">
      <div className="connect-modal-container">
        <div className="close-button-container">
          <button onClick={() => {onOutsideClick()}} className="btn-inverse" style={{border: "none"}}>
            <div className="close-button-icon">
              <XClose size={32} className="x-close-icon"/>
            </div>
          </button>
        </div>
        <div ref={connectRef} className="connect-buttons-container">
          {Object.keys(connectorsByName).map(name => {
            const currentConnector = connectorsByName[name];
            const activating = currentConnector === activatingConnector;
            const connected = currentConnector === connector && !error;
            const disabled = !triedAutoConnect || !!activatingConnector || connected || !!error;
            //*debug*console.log("ConnectOptions readout:");
            //*debug*console.log("COM currentConnector: ", currentConnector);
            //*debug*console.log("COM connector: ", connector);
            //*debug*console.log("COM activating: ", activating);
            //*debug*console.log("COM !!activatingConnector: ", !!activatingConnector);
            //*debug*console.log("COM connected: ", connected);
            //*debug*console.log("COM disabled: ", disabled);
            //*debug*console.log("COM !!error: ", !!error);
            //*debug*console.log("COM error: ", error);
            if (!!error) {
              console.log("COM error.code: ", error.code);
              console.log("COM getErrorMessage: ", getErrorMessage(error));
              console.log("COM error: ", error);
              console.log("COM error instanceof UnsupportedChainIdError: ", (error instanceof UnsupportedChainIdError));
            }

            return (
              <button 
                className={
                  activating ? "connect-modal-button btn-activating" :
                  connected ? "connect-modal-button btn-connected" :
                  "connect-modal-button"
                }
                style={{
                  cursor: disabled ? "unset" : "pointer"
                }}
                disabled={disabled}
                key={name}
                onClick={() => {
                  //*debug*console.log("currentConnector: ", currentConnector);
                  //*debug*console.log("connector: ", connector);
                  //*debug*console.log("activatingConnector: ", activatingConnector);
                  //*debug*console.log("connected: ", connected);

                  //*debug*console.log("setActivatingConnector(currentConnector)...");
                  setActivatingConnector(currentConnector);
                  //*debug*console.log("activate(connectorsByName["+name+"]");
                  activate(connectorsByName[name]);

                  onConnect();
                }}
              >
                <div className="connect-modal-button-content"
                  
                > 
                  {activating && 
                    <div>
                      <Spinner color={"black"} style={{height: "25%", margin: "auto" }} />
                      <p>Check you've completed signing in to your wallet...</p>
                    </div>
                  }
                  {connected && (
                    <span role="img" aria-label="check">
                      ✅
                    </span>
                  )}
                <img 
                  style={{width: "30%", maxWidth: "50px", margin: "auto"}} 
                  src={connectorLogosByName[name]} 
                  alt={connectorsByName[name]}
                />
                {name} 
                </div>
              </button>
            );
            })
          }
        </div>
      </div>
    </div>
    );
}

function OpenConnectModal({
  connector,
  triedAutoConnect,
  activatingConnector,
  setActivatingConnector,
  activate,
  deactivate,
  error,
  active,
  account,
  connectMsg,
  buttonClassName="btn-type-a"
  }) {
  //a single button to display the ConnectOptions modal
  //is the modal open?
  const [isOpen, setIsOpen] = React.useState(false);
  //*debug*console.log("OpenConnectModal: isOpen: ", isOpen);
  // const [dumbState, setDumbState] = React.useState(0);
  // console.log("dumbState: ", dumbState);

  const openModal = () => {
    //*debug*console.log("openingModal");
    setIsOpen(true);
  }

  const closeModal = () => {
    //*debug*console.log("closing connect modal");
    setIsOpen(false);
  }
  //const [refState, setRefState] = React.useState(0);

  // function useForceComponentRefresh () {
  //   console.log("refState: ", refState);
  //   return () => {setRefState(refState + 1)};
  // }
  
  // const forceRefresh = useForceComponentRefresh();

  return (
    <div className="connect-modal-open">

      <button 
        onClick={() => {
          if (!active && !error) {
            openModal()
          } else if (!!error && error instanceof UserRejectedRequestErrorWalletConnect) {
            //*debug*console.log("forcing refresh...");
            window.location.reload();
            //*debug*console.log("deactivating connector: ", connector);
            deactivate(connector);
          } else {
            deactivate(connector);
          }
        }}
        className={buttonClassName}
      >
     {/*{ !isOpen ? "CONNECT WALLET" : ""}*/}

        { (!active && !error) ? connectMsg : 
          (!!error) ? <span>Connection Error, Click to Try Again!</span> : 
          <div className="connect-modal-open-button-content">
            <span>
              {account.slice(0, 4) + "..." + account.slice(account.length-6)}&nbsp;
            </span>
            {powerIcon}
          </div> }
      </button>

      {!!error && console.log("CWB error found: ", error)}
      {!!error && console.log("getErrorMessage: ", getErrorMessage(error))}
      {!!error && <strong className="web3-error-descriptor">{getErrorMessage(error)}</strong>}
      { isOpen && (
        <ConnectOptionsModal
          connector={connector} 
          triedAutoConnect={triedAutoConnect}
          activatingConnector={activatingConnector}
          setActivatingConnector={setActivatingConnector}
          activate={activate}
          error={error}
          onOutsideClick={closeModal}
          onConnect={closeModal}
        />
      )}
    
    </div>
    );
}

function StickyFooterGreeting({numMinted, account}) {
  //*debug*console.log("render StickyFooterGreeting");

  if (!numMinted || !account) {
    return (
      <strong>
        <p>The Derpys are out there...</p>
        <p>Connect your wallet and GET DERPY!</p>
      </strong>
      );
  }
  else if (numMinted === 10000) {
    return (
      <strong>
        <p>
          All 10,000 Derpys have been rescued! Thank you for your support! You can still get Derpys on &nbsp;
          <a href={OPENSEAURL} target="_blank" rel="noopener noreferrer">OpenSea</a>
        </p>
      </strong>
      );
  } 
  else {
    return('');
  }
}

function StickyTokensBar(props) {
  //*debug*console.log("render StickyTokensBar");

  return (
    <div className="sticky-tokens-bar">
      <div className="sticky-tokens-block">
        <div className="sticky-content-container">
          <div className="sticky-footer-greeting">
            <StickyFooterGreeting numMinted={props.numTokensMinted} account={props.account}/>
          </div>
          <div className="sticky-footer-buttons-wrap">
            {props.account &&
              <LinkButton 
                href_="#mintFormId_0"
                linkToId="mintBarId_0"
                title_="CATCH A DERPY!"
                buttonClass="btn-type-b"
                handleScroll={handleScrollTo}
              />
            }
            <OpenConnectModal
              connector={props.connector} 
              triedAutoConnect={props.triedAutoConnect}
              activatingConnector={props.activatingConnector}
              setActivatingConnector={props.setActivatingConnector}
              activate={props.activate}
              deactivate={props.deactivate}
              error={props.error}
              active={props.active}
              account={props.account}
              connectMsg={"CONNECT WALLET"}
              buttonClassName={"btn-type-b"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function BannerStripeCustom(props) {
  return(
    <div className="banner-stripe">
      <div className="contents-wrap left">
        {props.left}
      </div>
      <div className="contents-wrap middle">
        {props.middle}
      </div>
      <div className="contents-wrap right">
        {props.right}
      </div>
      <div className="children-wrap">
        {props.children}
      </div>
    </div>
  )
}

function getContractByNetwork(chainId_) {
  switch( String(chainId_) ) {
    case "1":
      console.log("DERPYS CONTRACT ADDRESS: ", addresses.derpysMainnet);
      return addresses.derpysMainnet;
    case "3": 
      console.log("DERPYS CONTRACT ADDRESS: ", addresses.derpysRopsten);
      return addresses.derpysRopsten;
    case "4":
      console.log("DERPYS CONTRACT ADDRESS: ", addresses.derpysRinkeby);
      return addresses.derpysRinkeby;
    case "31337":
      return addresses.derpysLocal;
    default:
      return addresses.derpysMainnet;
  };
}

function getCurveKillaContractByNetwork(chainId_) {
  switch( String(chainId_) ) {
    case "1":
      return addresses.curveKillaMainnet;
    case "3": 
      return addresses.curveKillaRopsten;
    case "4":
      return addresses.curveKillaRinkeby;
    case "31337":
      return addresses.curveKillaLocal;
    default:
      return addresses.curveKillaMainnet;
  };
}

// function getDerpySpooksContractByNetwork(chainId_) {
//     switch( String(chainId_) ) {
//     case "1":
//       return addresses.derpySpooksMainnet;
//     case "3": 
//       return addresses.derpySpooksRopsten;
//     case "4":
//       return addresses.derpySpooksRinkeby;
//     case "31337":
//       return addresses.derpySpooksLocal;
//     default:
//       return addresses.derpySpooksMainnet;
//   };
// }

function handleScrollTo(eleId) {
  //*debug*console.log("eleId: ", eleId);
  let ele = document.getElementById(eleId);
  //*debug*console.log("ele: ", ele);

  ele.scrollIntoView({behavior: "smooth", block: "start"});
}

export function App() {
  //use useWeb3React() to access web3
  //'Connectors' connect to web3, i.e. metamask, ledger, walletconnect...
  //'library' is an ethers.js provider (set in getLibrary())
  //the useful state is stored as context (see react docs) and accessible in 
  //any child of the <Web3ReactProvider> component
  const Web3ReactContext = useWeb3React();
  const { 
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error 
  } = Web3ReactContext;
  const [ numTokensMinted, setNumTokensMinted ] = React.useState();
  // const [ setNumSpooksTokensMinted, numSpooksTokensMinted ] = React.useState();
  //*debug*console.log("numTokensMinted: ", numTokensMinted);
  //const ipfsTokenCid = Config.ipfsTokenCid;

  const derpyAddress = getContractByNetwork(chainId);
  const curveKillaAddress = getCurveKillaContractByNetwork(chainId);
  // const derpySpooksAddress = getDerpySpooksContractByNetwork(chainId);
  const openseaImageBaseUrl = OPENSEA_ASSET_ROOT + derpyAddress + '/';
  // const openseaSpooksImageBaseUrl = OPENSEA_ASSET_ROOT + derpySpooksAddress + '/';

  //console.log("openseaImageBaseUrl: ", openseaImageBaseUrl);
  //*debug*console.log("derpyAddress: ", derpyAddress);

  //*debug*console.log("connector: ", connector);
  //*debug*console.log("library: ", library);
  //*debug*console.log("account: ", account);
  //*debug*console.log("chainId: ", chainId);
  //*debug*console.log("active: ", active);
  //*debug*console.log("error: ", error);
  //*debug*console.log("Web3ReactContext: ", Web3ReactContext);

  const [activatingConnector, setActivatingConnector] = React.useState();
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const triedAutoConnect = useAutoConnectInjected();
  //*debug*console.log("triedAutoConnect = " + triedAutoConnect);
  
  //REMOVE THE AUTO CONNECT ATTEMPT BECAUSE IT FREEZES THE PAGE 
  //IF METAMASK IS NOT CONNECTED TO IMMEDIATELY
  //useInjectedMonitor(!triedAutoConnect);// || !!activatingConnector);
  
  //get the contract's current token supply
  const getNumTokensMinted = React.useCallback(() => {
    //*debug*console.log("firing getNumTokensMinted");
    async function getTokenSupply (provider_) {
      
      let prov;
      if (provider_) {
        prov = provider_;
      } else {
        prov = ethers.getDefaultProvider(chainId, {infura: process.env.REACT_APP_INFURA_DEFPROV_KEY});
      };

      const derpyContract = new ethers.Contract(derpyAddress, abis.derpys, prov);
      const tokenSupply = (await derpyContract.currentSupply()).toString();
      //*debug*console.log("tokenSupply: ", tokenSupply);
      //*debug*console.log("typeof tokenSupply: ", typeof tokenSupply);
      if (!isNaN(parseInt(tokenSupply, 10))) {
        //*debug*console.log("setNumTokensMinted to :", tokenSupply)
        setNumTokensMinted(tokenSupply);
      } else {
        console.log("could not interpret the returned currentSupply(): ", tokenSupply);
      };
    };
    getTokenSupply(library);
  }, [library, setNumTokensMinted, derpyAddress, chainId]);

  // const getNumSpooksTokensMinted = React.useCallback(() => {
  //   //*debug*console.log("firing getNumTokensMinted");
  //   async function getSpooksTokenSupply (provider_) {
      
  //     let prov;
  //     if (provider_) {
  //       prov = provider_;
  //     } else {
  //       prov = ethers.getDefaultProvider(chainId, {infura: process.env.REACT_APP_INFURA_DEFPROV_KEY});
  //     };

  //     const derpySpooksContract = new ethers.Contract(derpySpooksAddress, abis.derpySpooks, prov);
  //     const spooksTokenSupply = (await derpySpooksContract.totalSupply()).toString();
  //     //*debug*console.log("tokenSupply: ", tokenSupply);
  //     //*debug*console.log("typeof tokenSupply: ", typeof tokenSupply);
  //     if (!isNaN(parseInt(spooksTokenSupply, 10))) {
  //       //*debug*console.log("setNumTokensMinted to :", tokenSupply)
  //       setNumSpooksTokensMinted(spooksTokenSupply);
  //     } else {
  //       console.log("could not interpret the returned totalSupply(): ", spooksTokenSupply);
  //     };
  //   };
  //   getSpooksTokenSupply(library);
  // }, [library, setNumSpooksTokensMinted, derpySpooksAddress]);

  //ADD SPOOKS IN BELOW TOO??
  React.useEffect(() => {
    getNumTokensMinted();
  }, [connector, getNumTokensMinted, chainId]);

  //Media queries for conditional rendering
  const isTabletOrMobile = useMediaQuery({ query: "(max-device-width: 1023px"});

  return (
    <Body>
      <AllWrapper>
        <BgWrapper bgVid={bgVid4} bgPoster={bgPoster}>
          <ContentWrapper>
            <Web3ReactProvider getLibrary={getLibrary}>
                {/*{ 
                  chainId !== "1" &&
                    <StatusHeader chainId={chainId} />
                }*/}
                { !isTabletOrMobile &&
                  <HeaderBar>
                    <LinkButton
                      linkToId="id-dna"
                      title_="Derpy DNA"
                      buttonClass="btn-header"
                      handleScroll={handleScrollTo}
                    />
                    <LinkButton
                      linkToId="id-how-to"
                      title_="How it works"
                      buttonClass="btn-header"
                      handleScroll={handleScrollTo}
                    />
                    <LinkButton
                      linkToId="mintBarId_0"
                      title_="MINT!"
                      buttonClass="btn-header"
                      handleScroll={handleScrollTo}
                    />
                    <LinkButton
                      linkToId="id-contact"
                      title_="CONTACT"
                      buttonClass="btn-header"
                      handleScroll={handleScrollTo}
                    />
                  </HeaderBar>
                }
              
              {/*<header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />*/}
{/*              <Header>
                <OpenConnectModal
                  connector={connector} 
                  triedAutoConnect={triedAutoConnect}
                  activatingConnector={activatingConnector}
                  setActivatingConnector={setActivatingConnector}
                  activate={activate}
                  deactivate={deactivate}
                  error={error}
                  active={active}
                  account={account}
                  connectMsg={"CONNECT WALLET"}
                  buttonClassName={"btn-transparent"}
                />
              </Header>*/}
                <TitleBlock 
                  post={TITLE} 
                  media={{src: derpyHeadGif, alt: "derpy!"}}
                >
                  <OpenConnectModal
                    connector={connector} 
                    triedAutoConnect={triedAutoConnect}
                    activatingConnector={activatingConnector}
                    setActivatingConnector={setActivatingConnector}
                    activate={activate}
                    deactivate={deactivate}
                    error={error}
                    active={active}
                    account={account}
                    connectMsg={"Connect Wallet"}
                    buttonClassName={"btn-transparent"}
                  />

                </TitleBlock>


                <div className="progress-bar-wrap">
                  <h4>
                    Captured Derpys: {numTokensMinted}
                  </h4>
                </div>
              {/*  <PostBlock 
                  className="bg-transparent"
                  style={{margin: "0 0 15px 0", padding: "0 0 15px 0"}}
                >
                  <div className="link-button-div">
                    <h4>
                      DERPYSPOOKS ARE SUMMONING
                    </h4>
                    <LinkButton 
                      href_="#mintFormId_1"
                      linkToId="mintBarId_1"
                      title_="SUMMON A DERPY SPOOK"
                      buttonClass="btn-type-c"
                      handleScroll={handleScrollTo}
                    />
                  </div>
                </PostBlock>*/}

                <PostBlock style={{padding: "0", marginBottom: "10px"}}>
                  <MediaBlock>
                    <MediaGallery media={DERPYBLOCKGALLERY(32)} numTilesInRow={8} tileMargin={0} />
                  </MediaBlock>
                </PostBlock>
                  
{/*MINTING PAUSE COMMENT START*/}
                <PostBlock 
                  id="mintBarId_0" 
                  className="border-pink border-hover-turq"
                >
                  { numTokensMinted &&
                    <div>
                      <BannerStripeCustom
                        left={
                          <InfoByte 
                            infoType="mintPrice"
                            //numMinted={numTokensMinted}
                          />
                        }
                      />
                    </div>
                  }
                  {
                    account ? (
                        <MintBar 
                          mintFormId="mintFormId_0"
                          title={"MINT DERPYS"}
                          //subtitle=
                          message={{
                            firstline: "You are greenlit for action in the CATCH-ALL PROTOCOL",
                          }}
                          mintButtonMessage={"MINT DERPYS"}
                          provider={library} 
                          updateTokenSupply={getNumTokensMinted}
                          abis={abis}
                          derpyAddress={derpyAddress}
                          curveKillaAddress={curveKillaAddress}
                          //derpySpooksAddress={derpySpooksAddress}
                          targetContract={"curveKilla"}
                          maxNumber={50}
                        />
                    ) : (
                      <div>
                        <h2>
                          DERPY MINTING IS LIVE!
                        </h2>
                        
                        <OpenConnectModal
                          connector={connector} 
                          triedAutoConnect={triedAutoConnect}
                          activatingConnector={activatingConnector}
                          setActivatingConnector={setActivatingConnector}
                          activate={activate}
                          deactivate={deactivate}
                          error={error}
                          active={active}
                          account={account}
                          connectMsg={<h4>Connect to Mint and see your Derps</h4>}
                        />
                      </div>
                    )
                  }
                  {
                    account && (
                      <MediaBlock>
                        <DerpyScrollingGallery 
                          provider={library}
                          sigAddress={account}
                          contractAddress={derpyAddress}
                          contractAbi={abis.derpys}
                          openseaBaseAssetUrl={openseaImageBaseUrl}
                          ipfsUri={ chainId === 1 ?
                            viaIpfsGateway(Config.ipfsTokenCid_Mainnet, Config.ipfsGatewayUrl) :
                            viaIpfsGateway(Config.ipfsTokenCid, Config.ipfsGatewayUrl)
                          }
                          ipfsStatsUri={ chainId === 1 ? 
                            viaIpfsGateway(Config.ipfsTokenStatsCid_Mainnet, Config.ipfsGatewayUrl) :
                            viaIpfsGateway(Config.ipfsTokenStatsCid, Config.ipfsGatewayUrl)
                          }
                          refreshButtonMessage={"Refresh My Derps!"}
                          tokenName={"DERPYS"}
                        />
                      </MediaBlock>                    
                    )
                  }
                </PostBlock>
{/*MINTING PAUSE COMMENT END*/}

              <div className="hiding-image-organiser">
                <HidingImage src={derpyHead} alt="derp derp"/>
                {/*<HidingModalMakerImg 
                  imgSrc={derpyHead}
                  imgAlt={"derp derp"}
                  modalImgSrc={genesisDerpy}
                  modalImgAlt={"GENESIS DERPY"}
                  provider={library} 
                  updateTokenSupply={getNumTokensMinted}
                  abis={abis}
                  derpyAddress={derpyAddress}
                  curveKillaAddress={curveKillaAddress}
                  targetContract={"curveKilla"}
                  connectorModal={
                    <OpenConnectModal
                      connector={connector} 
                      triedAutoConnect={triedAutoConnect}
                      activatingConnector={activatingConnector}
                      setActivatingConnector={setActivatingConnector}
                      activate={activate}
                      deactivate={deactivate}
                      error={error}
                      active={active}
                      account={account}
                      connectMsg={<h4>Connect</h4>}
                    />
                  }
                />*/}
                <PostBlock className="border-turq"> {/*className="arrow-list">*/}
                  <Post post={SITUATIONREPORT} />
                </PostBlock>
              </div>

              <PostBlock id="id-dna" className="border-turq">
                <ColumnStatsBlock items={DERPYDNACOLS} title="DERPY DNA"/>
                <Post post={DERPYDNACONTENT} />
              </PostBlock>

              {/* <PostBlock id="id-pricing" className="border-turq">
                <Post 
                  post={PRICING}
                  flexWrap="row"
                  mediaStyle={{margin: "auto"}}
                />
              </PostBlock> */}

              {/* <PostBlock id="id-roadmap" className="border-turq">
                <Post 
                  post={{title: "ROADMAP"}}
                />
                <Roadmap
                  rows={rmRows}
                  extraItems={rmExtraItems}
                />
              </PostBlock> */}

              {/* <PostBlock id="id-whatIsCurveKilla" className="border-turq border-hover-pink">
                <Post 
                  post={CURVEKILLA101} 
                  mediaStyle={{margin: "auto"}}
                />
              </PostBlock> */}

              {/* REMOVED THE SPOOKS AFTER PHASE 1
              <PostBlock id="id-whatAreSpooks" className="border-turq border-hover-pink">
                <Post 
                  post={SPOOKS101} 
                  mediaStyle={{margin: "auto"}}
                />
              </PostBlock>
              */}


{/* SPOOKS GALLERY AND MINTING SECTION START COMMENT

                <PostBlock style={{padding: "0", marginBottom: "10px"}}>
                  <MediaBlock>
                    <MediaGallery media={DERPYSPOOKSBLOCKGALLERY(16)} numTilesInRow={8} tileMargin={0} />
                  </MediaBlock>
                </PostBlock>

                <PostBlock 
                  id="mintBarId_1" 
                  className="border-green border-hover-orange"
                >
                  { numTokensMinted &&
                    <div>
                      <BannerStripeCustom
                        left={
                          <InfoByte 
                            infoType="spookPrice"
                            //numMinted={numSpooksTokensMinted}
                          />
                        }
                      />
                    </div>
                  }
                  {
                    account ? (
                        <MintBar 
                          mintFormId="mintFormId_1"
                          title={"SUMMON DERPY SPOOKS"}
                          //subtitle={`Catch up to 50 in one go!`}
                          message={{
                            firstline: "THE SUMMONING IS UPON US",
                            nextline: "The portal can support 100 resurrections at once"
                          }}
                          mintButtonMessage={"SUMMON SPOOKS"}
                          provider={library} 
                          updateTokenSupply={getNumSpooksTokensMinted}
                          abis={abis}
                          derpyAddress={derpyAddress}
                          curveKillaAddress={curveKillaAddress}
                          derpySpooksAddress={derpySpooksAddress}
                          targetContract={"derpySpooks"}
                          maxNumber={100}
                          mintButtonClassName={"btn-type-c"}
                        />
                    ) : (
                      <div>
                        <h2>
                          DERPY SPOOKS ARE SUMMONING
                        </h2>
                        
                        <OpenConnectModal
                          connector={connector} 
                          triedAutoConnect={triedAutoConnect}
                          activatingConnector={activatingConnector}
                          setActivatingConnector={setActivatingConnector}
                          activate={activate}
                          deactivate={deactivate}
                          error={error}
                          active={active}
                          account={account}
                          connectMsg={<h4>Connect to Mint and see your DerpySpooks</h4>}
                          buttonClassName={"btn-type-c"}
                        />
                      </div>
                    )
                  }
                  {
                    account && (
                      <MediaBlock>
                        <DerpyScrollingGallery 
                          provider={library}
                          sigAddress={account}
                          contractAddress={derpySpooksAddress}
                          contractAbi={abis.derpySpooks}
                          openseaBaseAssetUrl={openseaSpooksImageBaseUrl}
                          ipfsUri={ chainId === 1 ?
                            viaIpfsGateway(Config.ipfsSpooksTokenCid_Mainnet, Config.ipfsGatewayUrl) :// ENDED GATEWAY SUBSCRIPTION Config.ipfsDedicatedGatewayUrl) :
                            viaIpfsGateway(Config.ipfsSpooksTokenCid, Config.ipfsGatewayUrl)
                          }
                          ipfsStatsUri={ chainId === 1 ? 
                            viaIpfsGateway(Config.ipfsSpooksTokenStatsCid_Mainnet, Config.ipfsGatewayUrl) :// ENDED GATEWAY SUBSCRIPTION Config.ipfsDedicatedGatewayUrl) :
                            viaIpfsGateway(Config.ipfsSpooksTokenStatsCid, Config.ipfsGatewayUrl)
                          }
                          refreshButtonMessage={"Refresh My Spooks!"}
                          buttonClassName={"btn-type-c"}
                          tokenName={"SPOOKS"}
                        />
                      </MediaBlock>                    
                    )
                  }
                  <p>
                    <strong>Check you are interacting with the DerpySpooks contract</strong><br/>
                    {derpySpooksAddress}<br/>
                    (<a className="pink-link" href={"#id-whatAreSpooks"}>
                      What are the DerpySpooks?
                    </a>)
                  </p>
                </PostBlock>

                <PostBlock style={{padding: "0", marginBottom: "10px"}}>
                  <MediaBlock>
                    <MediaGallery media={DERPYSPOOKSBLOCKGALLERY(16, 16)} numTilesInRow={8} tileMargin={0} />
                  </MediaBlock>
                </PostBlock>

SPOOKS GALLERY MINTING SECTION END*/}


              <PostBlock id="id-why-derpy" className="border-turq">
                <Post post={WHYDERPY} mediaStyle={{margin: "auto"}}/>
              </PostBlock>

              <PostBlock id="id-how-to" className="border-turq">
                <Post 
                  post={HOWTOCATCHADERPY}
                  flexWrap="row-reverse"
                />
              </PostBlock>

              <PostBlock id="id-details" className="border-turq">
                <Post 
                  post={DERPYDETAILS}
                  mediaStyle={{margin: "auto"}}
                />
                <p>
                  The contracts are completely open to read on etherscan :)<br/>
                  Derpys base smart contract:<br/>
                  <a
                    href={Config.etherscanBaseUrl + derpyAddress + "#code"} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    {derpyAddress}
                  </a>
                  <br/>
                  Minting contract:<br/>
                  <a
                    href={Config.etherscanBaseUrl + curveKillaAddress} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    {curveKillaAddress}
                  </a>
                </p>
              </PostBlock>

              {/* <PostBlock id="id-team" className="border-turq">
                <Post 
                  post={TEAM}
                  flexWrap="row"
                />
              </PostBlock> */}

              <PostBlock id={"id-contact"} className="border-turq">
                <Post 
                  post={CONTACT}
                  flexWrap="row"
                >
                  <div style={{margin: "auto"}}>
                   <ExternalLinkAsButton
                     href_={Config.twitterLink}
                     title_="TWITTER"
                     buttonClass="btn-type-a"
                   />
                 
                   <ExternalLinkAsButton
                     href_={Config.discordLink}
                     title_="DISCORD"
                     buttonClass="btn-type-a"
                   />

                   <ExternalLinkAsButton
                     href_={Config.instagramLink}
                     title_="INSTAGRAM"
                     buttonClass="btn-type-a"
                   />

                   <ExternalLinkAsButton
                     href_={Config.openseaLink}
                     title_="OPENSEA"
                     buttonClass="btn-type-a"
                   />
                  </div>


                  {/*<SocialTile social={SOCIALDISCORD} />
                  <SocialTile social={SOCIALTWITTER} />
                  <SocialTile social={SOCIALINSTAGRAM} />
                  <SocialTile social={SOCIALOTHER} />*/}

                </Post>
              </PostBlock>

            <PostBlock style={{padding: "0"}}>
              <MediaBlock>
                <MediaGallery media={DERPYBLOCKGALLERY(32)} numTilesInRow={8} tileMargin={0} />
              </MediaBlock>
            </PostBlock>

              <StickyTokensBar
                iconSrc={sampleDerpy1}
                numTokensMinted={numTokensMinted}
                connector={connector} 
                triedAutoConnect={triedAutoConnect}
                activatingConnector={activatingConnector}
                setActivatingConnector={setActivatingConnector}
                activate={activate}
                deactivate={deactivate}
                error={error}
                active={active}
                account={account}
                library={library}
                contractAddress={derpyAddress}
                //getTotalMinted={getNumTokensMinted}
              />
            </Web3ReactProvider>

            <PostBlock style={{padding: "10px 0px"}}>
              <p className="footer-line">&copy;2021, Derp Derp Derp Derp </p>
            </PostBlock>
          </ContentWrapper>
        </BgWrapper>
      </AllWrapper>
    </Body>
  );


}

// function App() {

  
//   return (
//     <div className="App">
//       <Web3Components/>
//     </div>
//   );
// }

export default App;
