import React from "react";
import { X as XClose } from "react-feather";

function JsonPair({attr, value}) {
	return(
		<div className="json-pair">
			<span><b>{attr}: </b></span>
			<span>{value}</span>
		</div>
	);
};

function JsonReadout(props) {
	const readout = [];
	const attributes = props.json.attributes;
	for (var attr = 0; attr < attributes.length; attr++) {
		//*debug*console.log("attr: ", attr);
		//*debug*console.log("attributes[attr]: ", attributes[attr]);
		const traitType = attributes[attr]["trait_type"];
		const value = attributes[attr]["value"];
		readout.push(
			<JsonPair attr={traitType} value={value} key={attr}/>
		);
	};
	return(
		<div className="json-readout">
			<div className="json-readout-title">
				<h4>{props.json.name}</h4>
			</div>
			<div className="json-readout-stats scrollable">
				{readout}
			</div>
		</div>
	);
};

export function StatsModal({
	imgSrc, 
	imgAlt,
	tokenId, 
	statsJson,
	onOutsideClick,
	openseaBaseAssetUrl
	}) {
	//Modal designed to give info about an image after it is clicked
	//attach a ref to track the next clicks
	const popRef = React.createRef();
	const [stats, setStats] = React.useState();

	//close the modal by clicking anywhere outside it
	React.useEffect(() => {
		const checkClickLocation = (event) => {
			if (popRef.current && popRef.current.contains(event.target)) {
				//clicked outside -> close the popup
				//props.onOutsideClick();
				onOutsideClick();
				//console.log("SM onOutsideClick()");
			};
		};
		document.addEventListener("click", checkClickLocation);
		return () => {
			//*debug*console.log("removing checkClickLocation listener from StatsModal");
			document.removeEventListener("click", checkClickLocation)
		};
	}, [popRef, onOutsideClick]);


	React.useEffect(() => {
		async function getJsonStats(url = "") {
	 		const response = await fetch(url).then(res => res.clone().json());
	 		//*debug*console.log("getStats response: ", response);
	 		//const iname = response.name;
	 		//const idescription = response.description;
	 		setStats(response);
	 	};
	 	try{
			getJsonStats(statsJson);//props.statsJson);
		} catch (err) {
			console.log(err);
		};
	 }, [statsJson]);//props.statsJson]);

	// const clickedOutside = () => {
	// 	console.log("Firing clickedOutside()!");
	// 	props.onOutsideClick()
	// };

	return(
		<div>

			<div className="modal-wrapper" > {/*onClick={props.closeModal}*/}		
				<div className="close-button-container">
          <button onClick={() => {onOutsideClick()}} className="btn-type-a" style={{border: "none"}}>
            <div className="close-button-icon">
              <XClose size={32} className="x-close-icon"/>
            </div>
          </button>
        </div>
				<div className="stats-modal-container">
					<div className="stats-modal-image-frame">
						<img className="stats-modal-img" src={imgSrc} alt={imgAlt} />
					</div>
					<div className="stats-modal-message-frame">
							{
								stats ? <JsonReadout json={stats} /> : 
								<div className="stats-modal-loading-message">
									<p>Fetching stats from the InterPlanetary File System...</p>
									<p>Give it a minute, this isn't running on AWS ;)</p>
									<p>if the stats don't load here, you can get them on <a href={openseaBaseAssetUrl + tokenId} target="_blank" rel="noopener noreferrer">Opensea</a></p>
								</div>
							}
					</div>
					<div className="stats-modal-footer">
						{stats ? <p><a href={openseaBaseAssetUrl + tokenId} target="_blank" rel="noopener noreferrer">See me on Opensea!</a></p> : ""}
					</div>
				</div>
			</div>
			<div ref={popRef} className="modal-overlay" />
		</div>
	);
};

export default function ImageMakeModalTile(props) {
	const [isOpen, setIsOpen] = React.useState(false);
	//ref to link to the opened Modal component
	//const popRef = React.createRef();

	const handleImgClick = () => {
		//console.log("IMMT: handleImgClick()");
		setIsOpen(!isOpen);
	};

	const closeModal = () => {
		//console.log("IMMT: closeModal() closing Modal")
		setIsOpen(false);
	};

	//*debug*console.log("ImageMakeModalTile, props.statsJson: ", props.statsJson);
	return (
		<div className="gallery-tile-user">
			<img 
				className="gallery-img-mini"
				src={props.url}
				alt={props.alt}
				onClick={handleImgClick}
				/>
				{	isOpen && (
					<StatsModal 
						imgSrc={props.url} 
						imgAlt={props.alt}
						tokenId={props.tokenId}
						statsJson={props.statsJson}
						//message="Sample message passed via props"
						//handleClick={handleImgClick}
						onOutsideClick={closeModal}
						openseaBaseAssetUrl={props.openseaBaseAssetUrl}
					/>
				)}
		</div>
	);
};