// import dotenv from "dotenv";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ImageMakeModalTile from "./ImageModalTile";

//import { viaIpfsGateway } from "./IpfsHelpers.js";
import abis from "../contracts/src/abis";
//import { Config } from "../config/default.js";
import { ethers } from "ethers";
// import { Contract } from "@ethersproject/contracts";

// dotenv.config();

// function GalleryImage (props) {
//   return (
//     <div className={props.className_} >
//       <img src={props.url} alt={props.alt}/>
//     </div>
//     );
// };

export function DerpyScrollingGallery(props) {
  //*debug*console.log("render DerpyScrollingGallery()");

  //manage tokens in the connected wallet
  const [tokenList, setTokenList] = React.useState([]);
  //*debug*console.log("DSG>> in connected wallet: tokenList: ", tokenList);

  //manage items in the scrolling gallery
  const [items, setItems] = React.useState([]);
  const [loaded, setIsLoaded] = React.useState(false);
  const [hasMoreTokens, setHasMoreTokens] = React.useState(true);
  //*debug*console.log("DSG>> currently in the scrolling gallery: items: ", items);

  const provider = props.provider;
  //*debug*console.log("DSG>> passed props.provider: ", provider);
  const sigAddress = props.sigAddress;
  // const setSigAddress = props.setSigAddress;
  const ipfsUri = props.ipfsUri; //viaIpfsGateway(Config.ipfsTokenCid); //ipfsChubbiesTokenCid); //
  const ipfsStatsUri = props.ipfsStatsUri; //viaIpfsGateway(Config.ipfsTokenStatsCid); //ipfsChubbiesStatsCid); //
  //*debug*console.log("DSG>> ipfsStatsUri: ", ipfsStatsUri);

  //set a ref for the scrollable container, to track if
  //overflowing or not
  const scrollRef = React.createRef();

  const refreshWalletTokens = React.useCallback(async () => {
    //refresh the tokens found in connected wallet with setTokenList()
    async function getOwnerTokens() {
      //*debug*console.log("firing refreshWalletTokens... ")
      //*debug*console.log("RWT>> getOwnerTokens from contract: ", props.contractAddress);
      //*debug*console.log("RWT>> provider: ", provider);
      //*debug*console.log("RWT>> initial tokenList: ", tokenList);
      if (provider) {
        //*debug*console.log("RWT>> provider found");
        const sig = await provider.getSigner();
        //*debug*console.log("RWT>> sig: ", sig);
        const sigAddr = await sig.getAddress();
        //*debug*console.log("RWT>> sigAddr: ", sigAddr);
        const derpyContract = new ethers.Contract(props.contractAddress, props.contractAbi, sig);
        try {
          const tokens = await derpyContract.listMyDerpys(sigAddr);
          //*debug*console.log("RWT>> tokens: ", tokens);
          //setSigAddress(sigAddr);
          //*debug*console.log("RWT>> setting setTokenList");
          setTokenList(tokens);
          //getNewItems(10);
        }
        catch (error) {
          console.log ("RWT>> error getting wallet tokens from contract, with 'listMyDerpys()': ", error);
        };
      } else {
        console.log("RWT>> NO provider found!: ", provider);
      };
    };
    await getOwnerTokens();
    //*debug*console.log("RWT>> final tokenList: ", tokenList);
  }, [provider, setTokenList, props.contractAddress]); //, setSigAddress
  

  const getNewItems = React.useCallback((count=10) => {
    //add next tokens from wallet to gallery
    //*debug*console.log(`GNI>> firing GetNewItems(${count})`)
    //*debug*console.log(`GNI>> CURRENTLY: items.length: ${items.length}, tokenList.length: ${tokenList.length}`);
    const newItems = [];
    // console.log("flag1");
    // for (let tmp = 0; tmp < items.length; tmp++){
    //   console.log("item " + tmp + ": " + items[tmp]);
    //   console.log("tokenList[" + tmp + "]: " + tokenList[tmp]);
    // };
    if (tokenList && items.length < tokenList.length){
      if (!hasMoreTokens) {setHasMoreTokens(true)};
      let numToFetch = tokenList.length - items.length;
      if (numToFetch > count) {
        numToFetch = count;
      };
      //*debug*console.log("GNI>> Fetching " + numToFetch + " tokens");
      for (let ii = 0; ii < numToFetch; ii++) {
        //next item to take from tokenList
        let nextItemId = items.length + ii;
        //*debug*console.log("GNI>> nextItemId: ", nextItemId);
        if (nextItemId < tokenList.length) {
          const nextToken = tokenList[ nextItemId ].toString();
          // console.log("newId = ", tokenList[ nextItemId ]);
          // console.log("newId (formatted):", nextToken);
          newItems.push(nextToken);
          // console.log("flag2");
        } else if (nextItemId === tokenList.length) {
          setHasMoreTokens(false);
        };
        // console.log("flag3");
      };
      if (newItems.length > 0) {
        //*debug*console.log("GNI>> adding newItems: ", newItems, " to items...")
        setItems([...items, ...newItems]);
        // console.log("flag5");
        setIsLoaded(true);
        // console.log("flag6");
      };
    } else {
      console.log("GNI>> no tokens found in the connected wallet.");
    };
  }, [items, setItems, tokenList, hasMoreTokens]);
  
  //if the connected wallet changes, reset and update the gallery
  React.useEffect(() => {
    //*debug*console.log("firing refreshWalletTokens effect... (sigAddress or contractAddress has changed)");
    setItems([]);
    setTokenList([]);
    //refreshWalletTokens();
  }, [sigAddress, props.contractAddress] );
  
  // React.useLayoutEffect(() => {
  //   if (tokenList && (tokenList.length > items.length)) {
  //     console.log("new items available")
  //     if (!hasMoreTokens) {setHasMoreTokens(true)};
  //     console.log("scrollRef.current.scrollHeight: ", scrollRef.current.scrollHeight);
  //     console.log("Math.abs(scrollRef.current.scrollTop): ", Math.abs(scrollRef.current.scrollTop));
  //     console.log("scrollRef.current.clientHeight: ", scrollRef.current.clientHeight);
  //     if (scrollRef.current.scrollHeight <= scrollRef.current.clientHeight) {
  //       console.log("scroll area not overflowing")
  //       console.log("getting new items");
  //       getNewItems(50);
  //     } else if (
  //       //check if component is fully scrolled
  //       scrollRef.current.scrollHeight - 
  //       Math.abs(scrollRef.current.scrollTop) === 
  //       scrollRef.current.clientHeight
  //       ) {
  //       console.log("scroll area not full");
  //       console.log("getting new items");
  //       getNewItems(50);
  //     };
  //   };
  // }, [tokenList, scrollRef, items.length, getNewItems]);
  

  //Don't need the following bootstrapper as long as the above
  //useLayoutEffect is used. (uLE() is auto called on first render)
  React.useEffect(() => {
   //*debug*console.log("firing gallery bootstrapper...");
   const waitForWalletTokens = async () => {
      refreshWalletTokens()
      //getNewItems(10)
      //setIsLoaded(true);
   };
   // refreshWalletTokens();
   waitForWalletTokens();//.then(getNewItems(10));
   //setIsLoaded(true);
  }, []); //purposefully empty dependencies - fire only once to bootstrap the gallery
  

  return (

    <div className="media-wrapper">
      <div className="media-wrapper-header">
        <button onClick={() => {
          //setItems([...items.slice(0,-1)]);
          let num = 10;
          if(items.length < 10) {
            num = 25;
          };
          refreshWalletTokens();
          getNewItems(num);
          //setHasMoreTokens(true);
          }}
          className={
            props.buttonClassName ?
            props.buttonClassName :
            "btn-type-a"
          }
        >
          {props.refreshButtonMessage}
        </button>
        <div className="wallet-contents">
          <span className="accent-col">
            {tokenList.length}&nbsp;
            {
              props.tokenName ?
              props.tokenName :
              "DERPYS"
            }</span>
          <span> in wallet:</span>
          <p>{sigAddress}</p>
        </div>
      </div>
      <div className="media-wrapper-body">
        <div id="scrollableDiv" ref={scrollRef} className="gallery-scroller scrollable" >
          <InfiniteScroll
            dataLength={items.length}
            next={() => {
              //*debug*console.log("firing next()");
              getNewItems(10)}
            }
            hasMore={hasMoreTokens}
            // loader={<p style={{color:"black"}}>Loading...</p>}
            endMessage={
              <p style={{ textAlign:"center",color:"black"}}>
                That's everything
              </p>
            }
            scrollableTarget="scrollableDiv"
            scrollThreshold={0.9}
            >
            {/*<div className="gallery-block">
              { loaded && (items.length > 0) ?
                items.map((item, index) => (
                  <GalleryImage 
                    className_="gallery-tile-user"
                    url={ipfsUri + "/" + item + ".gif"}
                    alt={"Derpy #" + item}
                    key={index}
                  />
                ))
                : ""
              }
            </div>
            <p>Try the above again with an ImageModalTile </p>*/}
            <div className="gallery-block">
              {/*{ !loaded && } PLACEHOLDER HERE*/}
              { loaded && (items.length > 0) ?
                  items.map((item, index) => (
                    <ImageMakeModalTile 
                      url={ipfsUri + "/" + item + ".gif"}
                      alt={"Derpy #" + item}
                      tokenId={item}
                      key={index}
                      statsJson={ipfsStatsUri + "/" + item}
                      openseaBaseAssetUrl={props.openseaBaseAssetUrl}
                    />
                  )) 
                  : "" 
              }
            </div>
          </InfiniteScroll>
        </div>
      </div>
      <div>
        {props.children}
      </div>
    </div>
    );
}