//import { Config } from "../config/default.js";

export function stripUriPrefix(uriOrCid) {
  if (uriOrCid.startsWith("ipfs://")) {
    return uriOrCid.slice("ipfs://".length)
  } else if (
    uriOrCid.startsWith("http://")) {
      return uriOrCid.slice("http://".length)
  } else if (
    uriOrCid.startsWith("https://")) {
    return uriOrCid.slice("https://".length)
  }
  return uriOrCid
}

export function ensureIpfsPrefix(uriOrCid) {
  let uri = uriOrCid.toString();
  if (!uri.startsWith("ipfs://")) {
    uri = "ipfs://" + uriOrCid;
  }

  if (uri.startsWith("ipfs://ipfs/")) {
    uri = uri.replace("ipfs://ipfs/", "ipfs://");
  }
  return uri
}

export function viaIpfsGateway(ipfsUri, gatewayString) {
  let gateway = gatewayString;
  if (gateway.endsWith("/")) {
    gateway = gateway.slice(0, gateway.length - 1);
  };
  return gateway + "/" + stripUriPrefix(ipfsUri)
}

export function extractCidString(ipfsUri) {
  return stripUriPrefix(ipfsUri).split("/")[0]
}

export function GetIpfsImageUri(props) {
  const baseUri = props.baseUri.toString();
  const fName = props.fname.toString();
  const fullUri = baseUri + "/" + fName;
  return (
    viaIpfsGateway(fullUri)
    );
}
