import { React } from "react";
import { 
  SOCIALTWITTER,
  SOCIALINSTAGRAM,
  //SOCIALDISCORD,
  SOCIALOTHER,
  //TESTTITLE,
  //TESTCONTENT,
  //TESTCONTENT2,
  //TESTCOLS,
  //TESTMEDIA,
  //TESTMEDIADERPY
} from "../content/testData.js";

import { Twitter, Instagram, ExternalLink, MessageSquare } from "react-feather";


export function AllWrapper(props) {
  return (
    <div className="all-wrapper">
      {props.children}
    </div>
  );
};

export function BgWrapper(props) {
  return (
    <div className="bg-wrapper">
      <video autoPlay loop playsInline muted poster={props.bgPoster} id="bg-vid">
        <source src={props.bgVid} type="video/mp4"/>
        no video for you
      </video>
      <div className="overlay-darken" />
      {props.children}
    </div>
  );
};

export function ContentWrapper(props) {
  return (
    <div className="content-wrapper">
      {props.children}
    </div>
  );
};

export function InfoPopup(props) {
  return (
    <div className="hover-popup">
      <div className="hover-popup-title">
        {props.title}
      </div>
      <div className="hover-popup-message">
        {props.message}
      </div>
    </div>
  )
}

export function SocialTile(props) {
  let socialLogo;
  if(props.social.title === "twitter") {
    socialLogo = <Twitter className="social-logo"/>;
  } else if (props.social.title === "instagram") {
    socialLogo = <Instagram className="social-logo"/>
  } else if (props.social.title === "discord") {
    socialLogo = <MessageSquare className="social-logo"/>
  } else {
    socialLogo = <ExternalLink className="social-logo"/>
  }
  return (
    <div className="social-tile">
      <a href={props.social.href} alt={props.social.title} target="_blank" rel="noopener noreferrer">
        {socialLogo} <span>&gt;{props.social.message}</span>
      </a>
    </div>
    );
}

export function TitleBlockSimple(props) {
  return (
    <div className="title-block">
      <h1 className="title-block-title">
        {props.post.title}
      </h1>
      <br/>
      <p className="title-block-message">
        <span style={{fontWeight: 'bold'}}> {props.post.messageBold} &gt; &nbsp; </span> 
        {props.post.message}
      </p>
    </div>
  );
};

export function TitleBlock(props) {
  return (
    <div className="title-block">
      <div className="title-flex">
        <div className="title-flex-left">
          <span className="title-block-big-title">
            {props.post.title}
          </span>
        </div>
        <div className="title-flex-center">
          <img className="title-block-image" src={props.media.src} alt={props.media.alt} />
        </div>
        <div className="title-flex-right">
          {props.children}
        </div>
      </div>
      <div className="title-block-message">
        <span style={{fontWeight: 'bold'}}> {props.post.messageBold} &nbsp; </span>
        <span style={{fontWeight: 'bold'}}> {props.post.message} </span>
      </div>

      <div className="title-block-buttons">
        {props.button && props.button}
        {props.button2 && props.button2}
        {props.button3 && props.button3}
      </div>

      <div className="title-block-buttons">
        {props.buttonSocial && props.buttonSocial}
        {props.buttonSocial2 && props.buttonSocial2}
        {props.buttonSocial3 && props.buttonSocial3}
      </div>
    </div>
  );
}

export function LinkButton(props) {
  return (
    <div className="link-button-wrap">
      {/*<a href={props.href_}>
        linklinklink
      </a>*/}
      <button 
        className={props.buttonClass} 
        onClick={() => {
          // let el = document.getElementById(props.href_);
          props.handleScroll(props.linkToId);
        }}
      >
        {props.title_}
      </button>  
    </div>
  );
}

export function ExternalLinkButton(props) {
  return (
    <div className="link-button-wrap">
        <button className={props.buttonClass}>
          <a href={props.href_} target="_blank" rel="noopener noreferrer" style={{color: "inherit"}}>
            {props.title_}
          </a>
        </button>
    </div>
    );
}

export function ExternalLinkAsButton(props) {
  return (
    <div className="external-link-wrap">
      <a href={props.href_} target="_blank" rel="noopener noreferrer" className="link-as-button">
        {props.title_}
      </a>
    </div>
    );
}


export function InPostMedia(props) {
  return <img className="post-media-img" src={props.media.src} alt={props.media.alt} />
}

export function PostBlock(props) {
  return (
    <div 
      className={
        props.className ? 
          "post-block " + props.className :
          "post-block"
        } 
      style={props.style} 
      id={props.id}
    >
      {props.children}
    </div>
  );
};

export function Post(props) {
  
  const postContent = () => {
    if (props.post.message instanceof Array){
      let paras = [];
      for ( let i = 0; i < props.post.message.length; i++) {
        paras.push(
          <p>{props.post.message[i]}</p>
        );
      };
      return (
        <div className="post-message">  
          {paras}
        </div>
        )
    } else {
      return (
        <div className="post-message">
          {props.post.message}
        </div>
      )
    }
  }

  return (
    <div>
      <div className="post-title-wrap">
        <h3 className="post-title">
          {props.post.title}
        </h3>
      </div>
      {
        props.post.subtitle &&
          <div className="post-subtitle-wrap">
            <h3 className="post-subtitle">
              {props.post.subtitle}
            </h3>
          </div>
      }
      <div 
        className={
          "post-content-wrap" + (
            (props.flexWrap === "row" || props.flexWrap === true) ? " flex-wrap" : 
              (props.flexWrap === "row-reverse") ? " flex-wrap-rev" :
               ""
        )}
        style={props.wrapStyle}
      >
        {props.post.media && (
          <div className="post-media" style={props.mediaStyle}>
            {
              props.post.media.preContent ? 
                <InPostMedia media={props.post.media.preContent} /> : 
                !props.post.media.postContent &&
                  <InPostMedia media={props.post.media} />
            }
          </div> 
        )}
        <div className="post-content" style={props.contentStyle}>
          { props.post.message && postContent() }
          { props.children }
        </div>
        {props.post.media &&
          <div className="post-media" style={props.mediaStyle}>
            {
              props.post.media.postContent ? <InPostMedia media={props.post.media.postContent} /> : ''
            }
          </div>
        }
      </div>
    </div>
    );
  };

function GalleryTile(props) {
  // if (!props.tileMargin) {
  //   let tileMargin = 0;
  // } else {
  //   let tileMargin = props.tileMargin;
  // };
  let tileWidth = String(100/props.numInRow - 2*props.tileMargin) + "%";
  // console.log("***tileWidth: ", tileWidth);
  // console.log("***numTilesInRow: ", props.numInRow);
  return (
    <img className={props.className_} src={props.item.src} alt={props.item.alt} style={{width: tileWidth, margin: `${props.tileMargin}px`}}/>
    );
}

// export function FlexColsTile(props) {
//   return (
//     <div className="flex-cols container" style={{display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "center", alignItems: "center"}}>
//       <div className="flex-cols left-col" style={{display: "flex", flexDirection: "column", flexWrap: "nowrap", justifyContent: "right", alignItems: "space-between"}}>
//         <img src={props.topLeft}/>
//         <img src={props.bottomLeft}/>
//       </div>
//       <div className="flex-cols center" style={{display: "flex", flexDirection: "column", flexWrap: "nowrap", justifyContent: "center", alignItems: "center"}}>
//         <img src={props.center}/>
//       </div>
//       <div className="flex-cols right-col" style={{display: "flex", flexDirection: "column", flexWrap: "nowrap", justifyContent: "left", alignItems: "space-between"}}>
//         <img src={props.topRight}/>
//         <img src={props.bottomRight}/>
//       </div>
//     </div>
//     );
// }

export function MediaGallery({media, tileClassName="gallery-tile", numTilesInRow, tileMargin}) {
  const gallery = [];
  let i;
  for (i = 0; i < media.length; i++) {
    gallery.push(
      <GalleryTile 
        className_={tileClassName}
        item={media[i]}
        key={media[i].key}
        numInRow={numTilesInRow}
        tileMargin={tileMargin}
      />
    )
  };
  return(
    <div className="gallery-block">
      {gallery}
    </div>
    );
}

export function MediaBlock(props) {
  // const gallery = [];
  // let i;
  // for (i = 0; i < props.media.length; i++) {
  //   gallery.push(<GalleryTile className_="gallery-tile" item={props.media[i]} key={props.media[i].key}/>)
  //   };
  return (
    <div className="media-block">
      {props.children}
    </div>
  );
}

export function MediaBlockFlex(props) {
  return (
    <div className="media-block-flex">
      {props.children}
    </div>
  );
}

function StatsTile(props) {
  let tileLogo;
  if (Object.keys(props.content).includes("src")) {
        tileLogo = <img className="stats-tile-logo" src={props.content.src} alt={props.content.alt} />
      }
  return (
    <div className="stats-tile-item">
      {tileLogo ? tileLogo : ''}
      <h3 className="stats-tile-title">
        {props.content.title}
      </h3>
      <div className="stats-tile-message">
        {props.content.message}
      </div>
    </div>
    );
}

export function ColumnStatsBlock(props) {
  const gallery = [];
  let i;
  for (i = 0; i < props.items.length; i++) {
    gallery.push(<StatsTile content={props.items[i]} key={props.items[i].key} />)
  };
  return (
    <div className="cols-block-container">
      <h2 className="cols-block-title">
        {props.title}
      </h2>
      <div className="cols-block-content">
        {gallery}
      </div>
    </div>
    );
}

export function FlexColsBlock(props) {
  // let cols = [];
  // for ( let c = 0; c < props.items.length; c++ ) {
  //   cols.push(<div className="flex-cols item">props.items[c]</div>);
  // };
  return (
    <div className="flex-cols wrap" style={props.style}>
      {props.children}
    </div>
    );
}

export function SocialsBar(props) {
  return (
    <div className="socials-bar post-block">
      <div className="socials-block">
        <SocialTile social={SOCIALTWITTER} />
        <SocialTile social={SOCIALINSTAGRAM} />
        <SocialTile social={SOCIALOTHER} />
      </div>
    </div>
  );
};

export function SocialsFlex(props) {
  return (
    <div className="socials-flex sf-wrap">
      <div className="socials-flex sf-block sf-left">
        {props.leftBlock}
      </div>
      <div className="socials-flex sf-block sf-center">
        {props.children}
      </div>
      <div className="socials-flex sf-block sf-right">
        {props.rightBlock}
      </div>
    </div>
  );
}

export function HidingImage(props) {
  //image that hides behind its previous sibling
  return (
    <div className="hiding-image-container">
      <div className="hiding-image-wrap">
        <img className="hiding-image" src={props.src} alt={props.alt} />
      </div>
    </div>
  );
};

export function BountyTile(props) {
  //return a small bounty tile for a bounty
  return (

    <div className="bounty-tile-wrap">
      <div className="bounty-target-wrap">
        <h2 className="bounty-target">
          {props.bountyId}
        </h2>
      </div>
      <div className="bounty-reward-wrap">
        <h2 className="bounty-reward">
          {props.bountyReward}
        </h2>
      </div>
      {
        props.bountyClaimed &&
          <div className="bounty-claimed-overlay-wrap">
            <code>CLAIMED</code>
          </div>
      }
    </div>
  );
};


export function InfoByte({infoType, numMinted}) {
  if (infoType === "tokensMinted") {
    return (
      <p>
        <strong>Derpys minted:</strong> {numMinted}
      </p>
    );
  } else if (infoType === "tokensRemaining") {
    return (
      <p>
        <strong>Derpys remaining:</strong> {10000 - numMinted}
      </p>
    );
  } else if (infoType === "mintPrice") {
    return (
      <p>
        <strong>Mint price: </strong> 0.045 ETH
      </p>
    );
  } else if (infoType === "spookPrice") {
    return (
      <p>
        <strong>Summoning price: </strong> 0.02 ETH <strong>or FREE to Derpy holders</strong>
      </p>
    );
  // } else if (infoType === "currentPrice") {
  //   let price;
  //   if (numMinted >= 0 && numMinted < 1000) {
  //     price = 0.02;
  //   } else if (numMinted >= 1000 && numMinted < 2000) {
  //     price = 0.03;
  //   } else if (numMinted >= 2000 && numMinted < 4000) {
  //     price = 0.05;
  //   } else if (numMinted >= 4000 && numMinted < 6000) {
  //     price = 0.08;
  //   } else if (numMinted >= 6000 && numMinted < 7500) {
  //     price = 0.13;
  //   } else if (numMinted >= 7500 && numMinted < 8500) {
  //     price = 0.21;
  //   } else if (numMinted >= 8500 && numMinted < 9000) {
  //     price = 0.34;
  //   } else if (numMinted >= 9000 && numMinted < 9500) {
  //     price = 0.55;
  //   } else if (numMinted >= 9500 && numMinted < 9900) {
  //     price = 0.89;
  //   } else if (numMinted >= 9900 && numMinted < 10000) {
  //     price = 1;
  //   } else {
  //     price = 999;
  //   }
    // return (
    //   <p>
    //     <strong>Current price: </strong> {price} ETH
    //   </p>
    // );
  }
}


