import React from "react";
import { ethers, BigNumber, utils } from "ethers";
//import { Button } from "../components"
//import NumericInput from "react-numeric-input";


class MintButton extends React.Component {
  constructor (props) {
    super(props);
    this.title = "MINT";
    this.type = "submit";
    this.formId_ = "default-form-id";
    this.className_ = "default-mint-button";

    if (!this.props.title || (typeof(this.props.title) !== "string")) {
      this.title = "MINT";
    } else {
      this.title = this.props.title;
    };

    if (!this.props.type || !(["submit", "button", "reset"].includes(this.props.type))) {
      this.type = "submit";
    } else {
      this.type = this.props.type;
    };

    if (!this.props.formId || (typeof(this.props.formId) !== "string")) {
      this.formId_ = "";
    } else {
      this.formId_ = this.props.formId;
    };

    if (!this.props.buttonClassName || (typeof(this.props.buttonClassName) !== "string")) {
      this.className_ = "";
    } else {
      this.className_ = this.props.buttonClassName;
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  };

  handleSubmit(event) {
    event.preventDefault();
    // console.log("firing handleSubmit in MintButton");
    this.props.onMintSubmit(event.target.value);
  };

  render () {
    return (
      <button 
        type={this.type}
        onClick={this.handleSubmit}
        className={this.className_}
        form={this.formId_}
      >
      {this.title}
      </button>
      );
  }
}

class NumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.formId_ = "default-form-id";
    this.className_ = "default-input-number";
    this.formClassName_ = "default-form-number";
  
    if (!this.props.formId || (typeof(this.props.formId) !== "string")) {
      this.formId_ = "";
    } else {
      this.formId_ = this.props.formId;
    };
  
    if (!this.props.inputClassName || (typeof(this.props.inputClassName) !== "string")) {
      this.className_ = "";
    } else {
      this.className_ = this.props.inputClassName;
    };
  
    if (!this.props.formClassName || (typeof(this.props.formClassName) !== "string")) {
      this.formClassName_ = "";
    } else {
      this.formClassName_ = this.props.formClassName;
    };

    this.handleNumChange = this.handleNumChange.bind(this);
    this.handleNumSubmit = this.handleNumSubmit.bind(this);
    this._handleNumChange = this._handleNumChange.bind(this);
  };

  handleNumChange(event) {
    // console.log("firing handleNumChange in NumberInput");
    this.props.onNumChange(event.target.value); 
  };

  _handleNumChange(event) {
    //*debug*console.log("firing _handleNumChange(event) with event=", event);
    //*debug*console.log("event.valueAsString: ", event.valueAsString);
    //*debug*console.log("event.valueAsNumber: ", event.valueAsNumber);
    //*debug*console.log("isNan(event.valueAsNumber): ", isNaN(event.value));
    //*debug*console.log("isNan(event.valueAsString): ", isNaN(event.value));
    //*debug*console.log("parseInt(event, 10): ", parseInt(event, 10));
    this.props.onNumChange(parseInt(event, 10));
  };

  _handleNumSumbit(event) {
    event.preventDefault();
    this.props.onNumSubmit(parseInt(event, 10));
  };

  handleNumSubmit(event) {
    event.preventDefault();
    // console.log("firing handleNumSubmit in NumberInput");
    // console.log("event.target.value: ", event.target.value);
    this.props.onNumSubmit(event.target.value); //.valueAsNumber); //
  };

  render() {
    // console.log("render NumberInput");
    return (
      <form 
        className={this.formClassName_}
        id={this.formId_}
        onSubmit={this.handleNumSubmit}
        > 
        <div className="mint-bar-input-wrapper">
          <input 
            className={this.className_}
            inputMode="numeric"
            type="number"
            max={this.props.maxNumber}
            min={1}
            step={1}
            value={this.props.storedValue}
            onChange={this.handleNumChange} 
          />
{/*          <NumericInput 
            className={this.className_}
            inputMode="numeric"
            type="text"
            max={50}
            min={1}
            step={1}
            value={this.props.storedValue}
            onChange={this._handleNumChange}
            strict />*/}
        </div>
      </form>
      );
    }
}

export class MintingContent extends React.Component {
  constructor(props) {
    super(props);
    this.mintFormId_ = this.props.mintFormId;
    this.state = {
      numToMint: 5,
      message: ''
    };
    this.handleNumChange = this.handleNumChange.bind(this);
    this.handleMint = this.handleMint.bind(this);
    this.handleMintViaCurveKilla = this.handleMintViaCurveKilla.bind(this);
    this.handleMintToDerpySpooks = this.handleMintToDerpySpooks.bind(this);
    this.chooseMintTarget = this.chooseMintTarget.bind(this);
    this.smartMintToCurveKilla = this.smartMintToCurveKilla.bind(this);
    this.getTokenSupply = this.getTokenSupply.bind(this);
  };

  //EVERY TIME THE NUMBER INPUT BOX CHANGES
  handleNumChange(e) {
    //*debug*console.log("firing handleNumChange in MintBar with payload: ", e);
    try {
      if (isNaN(parseInt(e, 10))) {
        throw TypeError;
      } else {
        this.setState({ numToMint: e });
        //*debug*console.log("input num changed: ", this.numToMint); 
      };
    } catch (error) {
      console.error(`Error in MintBar id ${this.mintFormId_}.\nMake sure you're inputting an integer!\n${error}`);
    };
  };

  //MINT DIRECTLY TO THE DERPYS CONTRACT
  async handleMint() {
    //*debug*console.log("firing handleSubmit in MintBar");
    //*debug*console.log("state in MintBar at submit time: ", this.state.numToMint);
    //get a signer
    try {
      const signer = await this.props.provider.getSigner()
      //const sigAddress = await signer.getAddress();
      // console.log("sigAddress: ", sigAddress);

      //connect to the contract
      const derpyContract = new ethers.Contract(this.props.derpyAddress, this.props.abis.derpys, signer);
      //get the price
      const catchCost = await derpyContract.getCatchCost();
      //*debug*console.log("catchCost.toString() from contract: ", catchCost.toString());
      const totalCost = catchCost.mul(BigNumber.from(this.state.numToMint));
      const formattedTotalCost = utils.formatUnits(totalCost, "ether");
      //*debug*console.log("formattedTotalCost: ", formattedTotalCost);
      console.log(
        `Minting ${this.state.numToMint} tokens at ${utils.formatEther(catchCost)} ETH each.\n` +
        `Total = ${utils.formatEther(totalCost)} ETH`
      );
      //mint the tokens
      const tx = await derpyContract.catchDerpy( BigNumber.from(this.state.numToMint),
        {
          value: utils.parseEther(formattedTotalCost)
        }
        );
      //const txReceipt = await tx.wait();
      console.log(
        `Minted ${this.state.numToMint} Derpys, at a total cost of ${formattedTotalCost} ETH.\n` +
        `Value returned in receipt: ${utils.formatEther(tx.value)} ETH.`
      );

      } catch (error) {
        console.log("error during minting: ", error);
      };
    try {
      //update the token supply
      this.getTokenSupply();
    } catch (err) {
      console.log("error updating the contract's token supply: ", err);
    };
  };

  //MINT TO DERPYS VIA THE CURVEKILLA CONTRACT
  async handleMintViaCurveKilla() {
    //console.log("firing handleMintViaCurveKilla in MintBar");
    //console.log("state in MintBar at submit time: ", this.state.numToMint);
    //get a signer
    try {
      const signer = await this.props.provider.getSigner();
      //const sigAddress = await signer.getAddress();
      //console.log("sigAddress: ", sigAddress);

      //connect to the contract
      const curveKilla = new ethers.Contract(
        this.props.curveKillaAddress,
        this.props.abis.curveKilla,
        signer
      );

      //get the price
      const catchCost = await curveKilla.MINT_PRICE();
      //console.log("catchCost.toString() from contract: ", catchCost.toString());
      const totalCost = catchCost.mul(BigNumber.from(this.state.numToMint));
      const formattedTotalCost = utils.formatUnits(totalCost, "ether");
      //console.log("formattedTotalCost: ", formattedTotalCost);
      console.log(
        `>>> MINTING VIA CURVEKILLA <<<\n` +
        `Minting ${this.state.numToMint} tokens at ${utils.formatEther(catchCost)} ETH each.\n` +
        `Total = ${utils.formatEther(totalCost)} ETH.`
      );
      //mint the tokens
      const tx = await curveKilla.mintDerpys( BigNumber.from(this.state.numToMint),
        {
          value: utils.parseEther(formattedTotalCost)
        }
      );
      //const txReceipt = await tx.wait();
      console.log(
        `Minted ${this.state.numToMint} Derpys, at a total cost of ${formattedTotalCost} ETH.\n` +
        `Value returned in receipt: ${utils.formatEther(tx.value)} ETH.\n` +
        `>>> MINTED VIA CURVEKILLA <<<`
      );
    } catch (error) {
      console.log("error during minting: ", error);
    };

    try {
      //update the token supply
      this.getTokenSupply();
    } catch (err) {
      console.log("error updating the contract's token supply: ", err);
    };
  };

  //MINT DIRECTLY TO THE DERPYS CONTRACT
  async handleMintToDerpySpooks() {
    //*debug*console.log("firing handleSubmit in MintBar");
    //*debug*console.log("state in MintBar at submit time: ", this.state.numToMint);
    //get a signer
    try {
      const signer = await this.props.provider.getSigner()
      //const sigAddress = await signer.getAddress();
      // console.log("sigAddress: ", sigAddress);

      //connect to the contract
      const derpySpooksContract = new ethers.Contract(
        this.props.derpySpooksAddress, 
        this.props.abis.derpySpooks, signer
      );
      //get the price
      const mintPrice = await derpySpooksContract.bloodOrEther();
      //*debug*console.log("catchCost.toString() from contract: ", catchCost.toString());
      const totalCost = mintPrice.mul(BigNumber.from(this.state.numToMint));
      const formattedTotalCost = utils.formatUnits(totalCost, "ether");
      //*debug*console.log("formattedTotalCost: ", formattedTotalCost);
      console.log(
        `Minting ${this.state.numToMint} tokens at ${utils.formatEther(mintPrice)} ETH each.\n` +
        `Total = ${utils.formatEther(totalCost)} ETH`
      );
      //mint the tokens
      const tx = await derpySpooksContract.summonDerpySpook(
        BigNumber.from(this.state.numToMint),
        {
          value: utils.parseEther(formattedTotalCost)
        }
      );
      //const txReceipt = await tx.wait();
      console.log(
        `Minted ${this.state.numToMint} DerpySpooks, at a total cost of ${formattedTotalCost} ETH.\n` +
        `Value returned in receipt: ${utils.formatEther(tx.value)} ETH.`
      );

      } catch (error) {
        console.log("error during minting: ", error);
      };
    try {
      //update the token supply
      this.getTokenSupply();
    } catch (err) {
      console.log("error updating the contract's token supply: ", err);
    };
  };

  //CHOOSE WHERE MINTING IS DIRECTED, FROM
  //this.props = 
  //  "derpys" || "curveKilla" || "derpySpooks" 
  async chooseMintTarget() {
    try {
      if (this.props.targetContract === "derpys") {
        await this.handleMint();
      } else if (this.props.targetContract === "curveKilla") {
        await this.handleMintViaCurveKilla();
      } else if (this.props.targetContract === "derpySpooks") {
        await this.handleMintToDerpySpooks();
      } else {
        throw new Error("Error selecting target contract");
      }
    } catch (err) {
      console.log("Error in chooseMintTarget()");
      console.error(err);
    }
  }

  //THIS CHOOSES BETWEEN MINTING DIRECTLY TO DERPYS
  //OR VIA CURVEKILLA, DEPENDING ON THE NUMBER OF 
  //TOKENS MINTED ON DERPYS.
  async smartMintToCurveKilla() {
    //console.log("firing chooseMintTarget CMT");
    const firstCurveKillaMintId = 2000;
    //get the supply
    try {
      const signer = await this.props.provider.getSigner();

      const DC = new ethers.Contract(
        this.props.derpyAddress,
        this.props.abis.derpys,
        signer
      );

      const derpySupply = await DC.currentSupply();
      const mintViaCurveKilla = derpySupply.gte(firstCurveKillaMintId);
      //console.log(`derpySupply: ${derpySupply}, mintViaCurveKilla?: ${mintViaCurveKilla}`);
      //if supply < 4000 mint via derpys
      //else if supply >= 4000 mint via curvekilla
      if (mintViaCurveKilla) {
        await this.handleMintViaCurveKilla();
      } else {
        await this.handleMint();
      }
    } catch (err) {
      console.log("error selecting mint function: ", err);
    }
  }

  getTokenSupply() {
    // console.log("firing getTokenSupply() in MintBar");
    this.props.updateTokenSupply();
  };

  render() {
    //*debug*console.log("render MintBar constructor");
    return (
      <div className="mint-bar-content-wrap">
        <div className="mint-bar-number-input-wrap">
          <NumberInput 
            formClassName="mint-bar-form" 
            inputClassName="input-num mint-bar-number" 
            provider={this.props.provider} 
            formId={this.mintFormId_} 
            onNumChange={this.handleNumChange}
            onNumSubmit={this.chooseMintTarget} 
            storedValue={this.state.numToMint}
            maxNumber={this.props.maxNumber}
          />
        </div>
        <div className="mint-bar-button-wrap">
          <MintButton
            title={this.props.mintButtonMessage}
            buttonClassName={
              this.props.mintButtonClassName ?
              this.props.mintButtonClassName :
             "btn-type-a"
            }
            type="submit"
            formId={this.mintFormId_}
            provider={this.props.provider}
            onMintSubmit={this.chooseMintTarget} 
          />
        </div>
      </div>
    );
  };
}

export class MintBar extends React.Component {
  constructor(props) {
    super(props);
    this.mintFormId_ = this.props.mintFormId;
  };

  render() {
    //*debug*console.log("render MintBar constructor");
    return (
      <div className="mint-bar-all-wrapper" id={this.props.mintBarId}>
        <div className="mint-bar-container">
          <h2 className="mint-bar-title">
            {this.props.title}
          </h2>
          {
            this.props.subtitle &&
              <h3 className="mint-bar-subtitle">{this.props.subtitle}</h3>
          }
          {
            this.props.message && 
              <div>
                {
                  this.props.message.firstline && 
                    <p>{this.props.message.firstline}</p>
                }
                {
                  this.props.message.nextline &&
                    <p>{this.props.message.nextline}</p>
                }
              </div>
          }
          <MintingContent
            mintFormId={this.mintFormId_}
            provider={this.props.provider}
            derpyAddress={this.props.derpyAddress}
            curveKillaAddress={this.props.curveKillaAddress}
            derpySpooksAddress={this.props.derpySpooksAddress}
            targetContract={this.props.targetContract}
            abis={this.props.abis}
            updateTokenSupply={this.props.updateTokenSupply}
            mintButtonMessage={this.props.mintButtonMessage}
            mintButtonClassName={
              this.props.mintButtonClassName &&
              this.props.mintButtonClassName
            }
            maxNumber={this.props.maxNumber}
          />
        </div>
      </div>
      );
  };
}

export class MintBarDouble extends React.Component {
  constructor(props) {
    super(props);
    this.mintFormId1_ = this.props.mintFormId1;
    this.mintFormId2_ = this.props.mintFormId2;
  };

  render() {
    //*debug*console.log("render MintBar constructor");
    return (
      <div className="mint-bar-all-wrapper" id={this.props.mintBarId}>
        <div className="mint-bar-container">
          <h2 className="mint-bar-title">
            {this.props.title}
          </h2>
          <div className="mint-bar-double-content-wrap">
            <div className="mint-bar-double-item mint-bar-double-first">
              {
                this.props.subtitle_1 &&
                  <h3 className="mint-bar-subtitle">{this.props.subtitle_1}</h3>
              }
              {
                this.props.message_1 && 
                  <div>
                    {
                      this.props.message_1.firstline && 
                        <p>{this.props.message_1.firstline}</p>
                    }
                    {
                      this.props.message_1.nextline &&
                        <p>{this.props.message_1.nextline}</p>
                    }
                  </div>
              }
              <MintingContent
                mintFormId={this.mintFormId1_}
                provider={this.props.provider}
                derpyAddress={this.props.derpyAddress}
                curveKillaAddress={this.props.curveKillaAddress}
                derpySpooksAddress={this.props.derpySpooksAddress}
                targetContract={this.props.targetContract1}
                abis={this.props.abis}
                updateTokenSupply={this.props.updateTokenSupply1}
                mintButtonMessage={this.props.mintButtonMessage1}
              />
            </div>

            <div className="mint-bar-double-item mint-bar-double-second">
              {
                this.props.subtitle_2 &&
                  <h3 className="mint-bar-subtitle">{this.props.subtitle_2}</h3>
              }
              {
                this.props.message_2 && 
                  <div>
                    {
                      this.props.message_2.firstline && 
                        <p>{this.props.message_2.firstline}</p>
                    }
                    {
                      this.props.message_2.nextline &&
                        <p>{this.props.message_2.nextline}</p>
                    }
                  </div>
              }
              <MintingContent
                mintFormId={this.mintFormId2_}
                provider={this.props.provider}
                derpyAddress={this.props.derpyAddress}
                curveKillaAddress={this.props.curveKillaAddress}
                derpySpooksAddress={this.props.derpySpooksAddress}
                targetContract={this.props.targetContract2}
                abis={this.props.abis}
                updateTokenSupply={this.props.updateTokenSupply2}
                mintButtonMessage={this.props.mintButtonMessage2}
              />
            </div>
          </div>
        </div>
      </div>
      );
  };
}